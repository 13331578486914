.footer-info {
  background-color: var(--emu-semantic-colors-text-light);
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-bottom: 42px;
  }

  > div {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
  }

  .cmp-text {
    p,
    li {
      color: var(--emu-semantic-colors-transparent-white-800);
      font-size: 12px;
      line-height: 15px;
    }

    ol {
      list-style-position: inside;
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
    }

    p {
      margin-bottom: 20px;
    }
  }
}