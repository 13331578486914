.juvederm-ba-image-comparator {
  background-color: var(--emu-semantic-colors-transparent-magenta-100);
  padding-top: 60px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-top: 30px;
    padding-right: 15px;
    padding-top: 30px;
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-top: 60px;
    padding-top: 70px;
  }

  &.js-emu-inview {
    .juvederm-ba-image-comparator__container {
      animation-name: slideIn;
    }
  }

  &__container {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    background-color: var(--emu-common-colors-transparent);
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
    opacity: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      display: flex;
    }

    > .container,
    > .comparisonslider {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex: 0 0 50%;
      }
    }
  }

  &__text {
    background-color: var(--emu-common-colors-transparent);
    padding-left: 15px;
    padding-right: 15px;

    .cmp-title__text {
      margin-bottom: 50px;
    }
  }

  .juvederm-comparisonslider {
    font-size: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-top: -60px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-top: -130px;
    }
  }
}