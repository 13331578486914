.p-download-brochure {
  .juvederm-brandstatements {
    justify-content: flex-start;
    margin-bottom: 150px;
    margin-top: 100px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-top: 60px;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-top: 90px;
    }

    &::before {
      height: 345px;
      left: 0;
      top: 0;
      width: 115px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        height: 540px;
        width: 245px;
      }
    }

    .title {
      margin-bottom: 35px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        padding-top: 90px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        padding-top: 60px;
      }
    }

    .image {
      padding-left: 15px;

      .cmp-image__image {
        aspect-ratio: 1;
        object-fit: cover;
        width: 100%;
      }
    }

    .text {
      margin-left: 0;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        order: 3;
      }
    }
    
    &-text {
      border-top: 1px solid var(--emu-semantic-colors-transparent-black-200);
      padding-top: 20px;
    }

    .button {
      display: inline-flex;
      margin-top: 40px;
      order: 4;
      padding-left: 15px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        left: 50%;
        position: absolute;
        top: 510px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        top: 480px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        top: 490px;
      }
    }
  }

  .juvederm-navigation .juvederm-navigation-wrapper-items {
    display: none;
  }

  .juvederm-scrollreveal {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-bottom: 110px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 140px;
    }
  }

  .juvederm-footnotes {
    margin-bottom: 60px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-bottom: 80px;
    }
  }

  .juvederm-footer {
    &-logos {
      padding-top: 0 !important; // needed to avoid nesting a lof of classes
    }

    .navigationlist.text {
      margin-bottom: 0;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-top: 0;
      }

      + .text {
        display: none;
      }
    }

    > .image {
      bottom: 0;
      display: flex;
      height: calc(100vw - 15px);
      justify-content: center;
      max-height: 100%;
      min-height: 377px;
      opacity: 0.05;
      overflow: hidden;
      position: absolute;
      right: 0;
      width: calc(100% + 15px);

      @include mq($emu-semantic-sizing-breakpoints-large) {
        height: 377px;
        width: 373px;
      }

      .emu-image {
        height: 100%;
        width: 100%;

        svg {
          bottom: 0;
          height: calc(100vw - 15px);
          min-height: 377px;
          overflow: hidden;
          position: absolute;
          right: 0;
          width: calc(100% - 15px);

          @include mq($emu-semantic-sizing-breakpoints-large) {
            height: 377px;
            width: 373px;
          }
        }

        g {
          scale: 1.45;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            scale: 1;
          }
        }
      }
    }

    .accordion {
      display: none !important; // needed to avoid nesting a lof of classes
    }
  }
}