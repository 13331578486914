.juvederm-testimonialmedia {
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--emu-semantic-sizing-content-max-width);
  position: relative;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    padding-bottom: 20px;
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 120px;
  }

  &.js-emu-inview {
    .juvederm-testimonialmedia__text {
      .cmp-title__text,
      blockquote {
        animation-name: slideIn;
      }
    }
  }

  &__text {
    background-color: var(--emu-semantic-colors-secondary-magenta-100);
    padding-bottom: 55px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 40px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-right: 98px;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-right: 190px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-right: 0;
      padding-right: 130px;
      position: relative;

      &::before {
        background-color: var(--emu-semantic-colors-secondary-magenta-100);
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
        width: 100vw;
      }

      &::after {
        background-color: var(--emu-semantic-colors-secondary-magenta-100);
        bottom: 0;
        content: "";
        position: absolute;
        right: 1px;
        top: 0;
        transform: translateX(100%);
        width: 550px;

        .juvederm-testimonialmedia--image & {
          width: 37px;
        }
      }
    }

    > .title,
    > .text {
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: -80px;
      }
    }

    .cmp-title__text,
    blockquote {
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    .cmp-title__text {
      font-family: var(--emu-semantic-font-families-body);
      margin-bottom: 5px;
      margin-left: 65px;

    }

    h4 {
      font-size: 14px;
      line-height: 20px;
    }

    h5 {
      font-size: 12px;
      line-height: 15px;
      
      i {
        font-weight: 400;
      }
    }

    .cmp-text {
      margin-bottom: 30px;
      margin-left: 65px;
      margin-top: 25px;

      blockquote {
        color: var(--emu-semantic-colors-text-light);
        font-family: var(--emu-semantic-font-families-heading);
        font-size: 41px;
        font-style: italic;
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 41px;
        position: relative;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          line-height: 45px;
          width: 260px;
        }

        &::before {
          background-color: var(--emu-semantic-colors-primary-purple-100);
          content: "";
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          top: 20px;
          transform: translateX(calc(-100% - 30px));
          width: 50px;
        }
      }
    }
  }

  &__media {
    background-color: var(--emu-common-colors-transparent);
    margin-right: 15px;
    margin-top: -40px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-bottom: 40px;
      margin-left: 176px;
      margin-right: 50px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 60px;
      
      .juvederm-testimonialmedia--image & {
        margin-top: 0;
        padding-top: 60px;
      }

      &::before {
        .juvederm-testimonialmedia--image & {
          background-color: var(--emu-semantic-colors-secondary-magenta-100);
          content: "";
          height: 60px;
          position: absolute;
          top: 0;
          width: 73%;
        }
      }
    }

    > .image {
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: auto;
        width: 725px;
      }
    }

    > .button {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: auto;
        max-width: 725px;
      }

      .juvederm-testimonialmedia__button {
        backdrop-filter: blur(10px);
        background-color: var(--emu-semantic-colors-transparent-white-600);
        border: 0;
        border-radius: 50%;
        color: var(--emu-semantic-colors-primary-purple-100);
        cursor: pointer;
        display: flex;
        height: 73px;
        justify-content: center;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: background-color 0.2s ease;
        width: 73px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          height: 122px;
          width: 122px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          height: 142px;
          width: 142px;
        }

        &:hover {
          background-color: var(--emu-common-colors-white);
        }

        &::before {
          border: 11px solid var(--emu-common-colors-transparent);
          border-left: 16px solid var(--emu-semantic-colors-primary-purple-100);
          border-right: none;
          content: "";
          display: block;
          transform: translate(20%, 5%);
          width: 0;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            border-bottom-width: 19px;
            border-left-width: 26px;
            border-top-width: 19px;
          }

          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            border-bottom-width: 22px;
            border-left-width: 30px;
            border-top-width: 22px;
          }
        }
      }

      .cmp-button__text {
        font-size: 0;
      }
    }

    .cmp-image__image {
      max-height: 55vw;
      object-fit: cover;
      width: 100%;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        max-height: 40vw;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        max-height: 408px;
      }
    }
  }

  &__modal {
    .modal-wrapper {
      background-color: var(--emu-semantic-colors-transparent-black-600);
    }

    .modal-content {
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50%;
      max-width: 830px;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      width: calc(100vw - 30px);

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-top: 25%;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-top: 8%;
        max-width: 1016px;
      }

      .main-close {
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
        margin-top: -60px;
        width: 40px;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-right: -60px;
          margin-top: 0;
        }

        &:hover {
          opacity: 1;

          span {
            transform: rotate(90deg);
          }
        }

        span {
          color: var(--emu-common-colors-white);
          font-size: 70px;
          font-weight: 300;
          transition: transform 0.2s ease;
        }
      }
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateY(50px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .juvederm-testimonialmedia {
    &__text {
      .cmp-text {  
        blockquote {
          font-family: var(--emu-semantic-font-families-heading-assistant);
        }
      }
      .cmp-title__text {
        font-family: var(--emu-semantic-font-families-body-assistant);  
      }
    }
  }
}