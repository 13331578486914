.juvederm-brandstatements {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 70px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    justify-content: flex-start;
  }

  &.js-emu-inview {
    .title {
      animation-name: slideIn;
    }

    .text {
      animation-name: revealFromLeft;
    }

    .image {
      animation-name: revealFromBottom;
    }
  }

  .title,
  .text,
  .image {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .title {
    width: 100%;
    word-break: break-word;
    margin-bottom: 20px;
    padding-top: 50px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0;
    font-size: 41px;
    z-index: var(--emu-common-other-z-index-layer);
    line-height: 45px;
    opacity: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      width: 66.67%;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      font-size: 61px;
      line-height: 70px;
      padding-top: 120px;
    }
  }

  .text {
    order: 1;
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
    margin-left: 16.667%;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      width: 50%;
      margin: 0;
      padding-left: 15px;
      padding-right: 15px;
      order: unset;
    }

    .text {
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 100%;
        margin: 0;
      }
    }

    p {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 30px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 18px;
      }
    }
  }

  &:before {
    background-color: var(--emu-semantic-colors-transparent-magenta-100);
    content: '';
    height: 445px;
    width: calc(33.333vw - 15px);
    position: absolute;
    right: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      height: 405px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      height: 620px;
      width: 33.95%;
    }
  }

  .image {
    flex: 0 0 100%;
    width: 100%;
    z-index: var(--emu-common-other-z-index-layer);
    top: 0;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 15px;
    margin-bottom: 25px;
    position: relative;
    aspect-ratio: 4 / 3;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      flex: 0 0 50%;
      padding-left: 15px;
      order: 1;
      margin-bottom: 0;
    }
  }
}
