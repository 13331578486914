footer.experiencefragment {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--emu-semantic-colors-text-light);
  position: relative;
  font-family: var(--emu-semantic-font-families-body);

  .juvederm-footer {
    background-color: var(--emu-semantic-colors-text-light);
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 0;
    padding-right: 0;
    color: var(--emu-semantic-colors-transparent-white-700);
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      max-width: var(--emu-semantic-sizing-content-max-width);
    }

    .text {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;

      .text {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }
      .cmp-text {
        &.aaaem-text {
          p {
            color: var(--emu-semantic-colors-transparent-white-600);
          }
          ol {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            list-style-position: inside;
          }
        }
      }
      &:last-child {
        p {
          font-size: 12px;
          line-height: 1.25;
          margin-top: 20px;
          margin-bottom: 20px;
          margin-left: 0;
          margin-right: 0;
          color: var(--emu-semantic-colors-transparent-white-700);
        }
      }
      & + .container {
        border-bottom: 1px solid var(--emu-semantic-colors-secondary-purple-100);
      }
    }

    > .container + .container {
      border-top: 1px solid var(--emu-semantic-colors-secondary-purple-100);
    }

    .responsivegrid {
      .cmp-container {
        display: flex;
        justify-content: end;
        background-color: var(--emu-semantic-colors-text-light);
        padding-top: 0;
        padding-bottom: 30px;
        padding-left: 0;
        padding-right: 0;
        .button {
          .aaaem-button {
            &.aaaem-button__primary-filled {
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 0;
              padding-right: 0;
              margin-left: 30px;
              &:active {
                outline: none;
              }
              &:active,
              &:hover {
                color: var(--emu-common-colors-white);
                & svg {
                  fill: var(--emu-common-colors-white);
                }
              }
              svg {
                margin-right: 0;
                height: 30px;
                width: 30px;
              }
            }
          }
          &:first-child {
            flex-grow: 2;
            a {
              &:hover,
              &:active {
                background-color: var(
                  --emu-semantic-colors-transparent-white-300
                );
                color: var(--emu-common-colors-white);
              }
            }
          }
        }
      }
      .aaaem-container__position-static {
        padding-top: 30px;
        padding-bottom: 0;
        .text {
          display: none;
        }
        .aem-Grid {
          .aem-GridColumn.aem-GridColumn--default--12:first-child {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              width: 33.33%;
              float: right;

              .logo-container {
                width: 100%;
              }
            }
          }
        }
        .juvederm-footer-logos {
          flex-direction: column;
          align-items: center;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            align-items: end;
          }

          .image {
            margin-bottom: 40px;
          }

          > .container {
            align-items: center;
            display: flex;
            flex-direction: row;

            .cmp-container {
              align-items: center;
              padding-bottom: 0;
            }

            .image {
              flex-shrink: 0;
              margin-bottom: 0;
            }

            .cmp-image img {
              height: 33px;

              @include mq($emu-semantic-sizing-breakpoints-large) {
                height: 30px;
              }
            }

            .text {
              display: block;
              padding-bottom: 0;
              padding-right: 0;

              p {
                color: var(--emu-common-colors-white);
              }
            }
          }
        }
      }
    }

    .single-logo {
      margin-bottom: 90px;

      @include mq('medium') {
        margin-bottom: 0;
      }
      &.cmp-container {
        display: grid;
        justify-content: center;
      }

      .responsivegrid {
        .aaaem-container__position-static {
          .aem-GridColumn.aem-GridColumn--default--12:first-child {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              width: 100%;
            }
          }
        }
      }
    }
    
    > .navigationlist {
      margin-top: 0;
      margin-bottom: 55px;
      margin-left: 0;
      margin-right: 0;
      padding-top: 40px;
      border-top: 1px solid var(--emu-semantic-colors-secondary-purple-100);
      z-index: 2;
      position: relative;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        position: relative;
        margin-top: 100px;
      }

      .emu-navigation__content-wrapper {
        position: relative;
      }

      .emu-navigation {
        background-color: var(--emu-common-colors-transparent);
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
      }

      .emu-navigation__menu--level-0 {
        display: block;
        text-align: center;
        width: fit-content;
        max-width: 80%;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;

        .emu-navigation__item {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 10px;
          padding-right: 10px;
          display: inline-block;

          .nav-item {
            border: 0;
            display: inline-block;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;

            &:hover {
              background-color: var(--emu-common-colors-transparent);
            }

            a {
              line-height: 1.85;
              font-size: 12px;
              font-weight: 400;
              color: var(--emu-semantic-colors-transparent-white-600);
              padding-bottom: 0;
              padding-left: 0;
              padding-right: 0;
              padding-top: 0;

              &:hover,
              &:active {
                text-decoration: underline;
                color: var(--emu-common-colors-white);
              }
            }
          }
        }
      }

      & + .text {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        @include mq($emu-semantic-sizing-breakpoints-large) {
          position: relative;
          margin-top: 55px;
        }
        & + .image {
          height: calc(100vw - 15px);
          max-height: 100%;
          min-height: 377px;
          width: calc(100% + 15px);
          opacity: 0.05;
          justify-content: center;
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          overflow: hidden;
          @include mq($emu-semantic-sizing-breakpoints-large) {
            height: 377px;
            width: 373px;
          }
          .emu-image {
            width: 100%;
            height: 100%;
            svg {
              width: calc(100% - 15px);
              height: calc(100vw - 15px);
              bottom: 0;
              position: absolute;
              min-height: 377px;
              overflow: hidden;
              right: 0;
              @include mq($emu-semantic-sizing-breakpoints-large) {
                height: 377px;
                width: 373px;
              }
            }
            g {
              scale: 1.45;
              @include mq($emu-semantic-sizing-breakpoints-large) {
                scale: 1;
              }
            }
          }
        }
      }
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  footer.experiencefragment {
    font-family: var(--emu-semantic-font-families-body-assistant);
  }
}
