@font-face {
  font-display: swap;
  font-family: 'Gambetta-Variable';
  font-style: normal;
  font-weight: 300 700;
  src: url('./assets/fonts/Gambetta/Gambetta-Variable.woff2') format("woff2"),
    url('./assets/fonts/Gambetta/Gambetta-Variable.woff') format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Gambetta-Variable';
  font-style: italic;
  font-weight: 300 700;
  src: url('./assets/fonts/Gambetta/Gambetta-VariableItalic.woff2') format("woff2"),
    url('./assets/fonts/Gambetta/Gambetta-VariableItalic.woff') format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Satoshi-Variable';
  font-style: normal;
  font-weight: 300 700;
  src: url('./assets/fonts/Satoshi/Satoshi-Variable.woff2') format("woff2"),
    url('./assets/fonts/Satoshi/Satoshi-Variable.woff') format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Satoshi-Variable';
  font-style: italic;
  font-weight: 300 700;
  src: url('./assets/fonts/Satoshi/Satoshi-VariableItalic.woff2') format("woff2"),
    url('./assets/fonts/Satoshi/Satoshi-VariableItalic.woff') format("woff");
}