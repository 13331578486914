.juvederm-faqshort {
  margin-bottom: 126px;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--emu-semantic-sizing-content-max-width);
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-bottom: 150px;
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    display: flex;
    gap: 30px;
    margin-bottom: 53px;
  }

  .image {
    display: none;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      display: block;
      margin-left: -85px;
    }
  }

  &__container {
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    > .button {
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-top: auto;
      }
    }
  }

  .cmp-title__text {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 30px;
    margin-bottom: 30px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      font-size: 27px;
      line-height: 35px;
      margin-bottom: 17px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 20px;
      margin-top: 40px;
    }
  }

  .aaaem-accordion {
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    &__header {
      border-bottom: 0;
      border-top: 1px solid var(--emu-semantic-colors-contrast-grey-100);
      padding-bottom: 14px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 5px;

      &.-ba-is-active {
        .aaaem-accordion__icon::before {
          background-color: var(--emu-common-colors-transparent);
          transform: rotate(135deg);
        }
      }
    }

    &__title {
      color: var(--emu-semantic-colors-text-light);
      font-family: var(--emu-semantic-font-families-body);
      font-size: 16px;
      font-weight: bolder;
      line-height: 30px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        font-size: 18px;
        line-height: 30px;
      }
    }

    &__icon {
      display: block;
      height: 13px;
      position: relative;
      width: 13px;

      &::before {
        background-color: var(--emu-common-colors-transparent);
        border-bottom: 2px solid var(--emu-semantic-colors-primary-purple-100);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-left: 2px solid var(--emu-semantic-colors-primary-purple-100);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        content: '';
        height: 13px;
        left: unset;
        position: absolute;
        right: 8px;
        top: 2px;
        transition: 0.3s;
        transform: translateY(0) rotate(-45deg);
        width: 13px;
        will-change: transform;
      }

      &::after {
        display: none;
      }
    }

    &__panel {
      &.-ba-is-active {
        max-height: none !important; /* important needed to overwrite a max-height set with JS that is too small */
      }

      .js-badger-accordion-panel-inner {
        padding-left: 0;
        padding-right: 45px;
        padding-top: 0;
      }
    }

    .cmp-text {
      p,
      li {
        color: var(--emu-semantic-colors-text-light);
        font-size: 14px;
        line-height: 25px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          font-size: 16px;
          line-height: 30px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          line-height: 25px;
        }
      }

      p,
      ul {
        margin-bottom: 20px;
      }

      ul {
        padding-left: 0;
      }

      li {
        padding-left: 10px;
        position: relative;

        &::before {
          content: '·';
          left: 0;
          position: absolute;
          vertical-align: middle;
        }

        &::marker {
          display: none;
        }
      }

      sup {
        font-weight: 500;
      }
    }
    &__header {
      &.-ba-is-active {
        .aaaem-accordion__button {
          &.caret-icon{
            .aaaem-accordion__icon{
                transform: unset;
                  &::before {
                    background-color: var(--emu-common-colors-transparent);
                    transform: rotate(135deg);
                  }
              }
            }          
          }
        }
      }
    &__button {
      &.caret-icon{
        .aaaem-accordion__icon{
            display: block;
            height: 13px;
            position: relative;
            width: 13px;      
            &::before {
              background-color: var(--emu-common-colors-transparent);
              border-bottom: 2px solid var(--emu-semantic-colors-primary-purple-100);
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              border-left: 2px solid var(--emu-semantic-colors-primary-purple-100);
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              content: '';
              height: 13px;
              left: unset;
              position: absolute;
              right: 8px;
              top: 2px;
              transition: 0.3s;
              transform: translateY(0) rotate(-45deg);
              transform-origin: unset;
              width: 13px;
              will-change: transform;
            }      
            &::after {
              display: none;
            }
        }
      }
    }
  }
}
// Country overrides
html[lang='he'],
html[lang='he_il'] {
  .juvederm-faqshort {
    .aaaem-accordion {
      &__title {
        font-family: var(--emu-semantic-font-families-body-assistant);
      }
    }
  }
}
