.scrollreveal {
  position: relative;

  .emu {
    &-scroll-reveal {
      max-width: 100%;
      width: 100%;

      &__container {
        height: 600px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          height: 480px;
        }

        @include mq($emu-semantic-sizing-breakpoints-large) {
          height: 435px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          padding-top: var(
            --emu-component-containers-scroll-reveal-padding-top-narrow
          );
          padding-bottom: var(
            --emu-component-containers-scroll-reveal-padding-bottom-narrow
          );
          padding-left: var(
            --emu-component-containers-scroll-reveal-padding-left-narrow
          );
          padding-right: var(
            --emu-component-containers-scroll-reveal-padding-left-narrow
          );
          height: 405px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          padding-top: var(
            --emu-component-containers-scroll-reveal-padding-top-wide
          );
          padding-bottom: var(
            --emu-component-containers-scroll-reveal-padding-bottom-wide
          );
          padding-left: var(
            --emu-component-containers-scroll-reveal-padding-left-wide
          );
          padding-right: var(
            --emu-component-containers-scroll-reveal-padding-left-wide
          );
          height: 600px;
        }

        &::before {
          content: '';
          position: absolute;
          background-color: var(--emu-semantic-colors-transparent-grey-100);
          height: 100%;
          width: calc(16vw - 15px);
          transition: 0.8s ease;
          top: 0;
        }
      }

      &__content-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          flex-direction: row;
        }
      }

      &__static-content-container {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 40px;
        margin: 0;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          max-width: 475px;
        }
      }

      &__progressbar-container {
        margin-bottom: 0;
        margin-top: 22px;
      }

      &__slides {
        max-width: 100%;
        margin-left: 62px;
        padding-left: 15px;
        padding-right: 15px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          max-width: 665px;
          margin-left: 96px;
        }

        @include mq($emu-semantic-sizing-breakpoints-large) {
          max-width: 55%;
          margin: 0;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          max-width: 540px;
        }
      }

      h2.emu-title__text {
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);
        margin-right: 30px;
        letter-spacing: 0;

        font: var(
          --emu-component-containers-scroll-reveal-header-text-typography-narrow
        );

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          font: var(
            --emu-component-containers-scroll-reveal-header-text-typography-narrow
          );
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font: var(
            --emu-component-containers-scroll-reveal-header-text-typography-wide
          );
        }
      }

      .card {
        .emu-card__title {
          .emu-title {
            h3.emu-title__text {
              font: var(
                --emu-component-containers-scroll-reveal-header-text-typography-narrow
              );
              font-style: italic;
              font-size: 18px;

              @include mq($emu-semantic-sizing-breakpoints-medium) {
                font-style: italic;
              }

              @include mq($emu-semantic-sizing-breakpoints-xx-large) {
                font: var(
                  --emu-component-containers-scroll-reveal-header-text-typography-wide
                );
                font-style: italic;
              }
            }
          }

          .emu-title h3.emu-title__text {
            @include mq($emu-semantic-sizing-breakpoints-medium) {
              font-weight: 500;
            }
          }
        }
      }
    }

    &-card {
      display: flex;
      grid-column: 1/1;
      grid-row: 1/1;
      margin-bottom: 60px;

      &__background-image {
        margin-right: 15px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          margin-right: 25px;
        }
      }

      &__wrapper {
        margin: 0;
        padding-left: 15px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          margin-top: 0;
          margin-right: 0;
          margin-bottom: 20px;
          margin-left: 0;
        }
      }

      &__title-wrapper {
        margin-bottom: 15px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          margin-bottom: 20px;
        }
      }

      &__body {
        p {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }

  .card[aria-hidden='false'] {
    .emu-image {
      animation: fadeIn 0.2s;
    }
  }

  .card[aria-hidden='true'] {
    .emu-image {
      opacity: 0;
      transition: 0.8s ease;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
// Country overrides
html[lang='he'],
html[lang='he_il'] {
  .scrollreveal {
    .emu {
      &-scroll-reveal {
        font: var(
          --emu-component-containers-scroll-reveal-header-assistant-text-typography-narrow
        );
        @include mq($emu-semantic-sizing-breakpoints-large) {
          font: var(
            --emu-component-containers-scroll-reveal-header-assistant-text-typography-wide
          );
        }
        h2.emu-title__text {
          font: var(
            --emu-component-containers-scroll-reveal-header-assistant-text-typography-narrow
          );
          @include mq($emu-semantic-sizing-breakpoints-medium) {
            font: var(
              --emu-component-containers-scroll-reveal-header-assistant-text-typography-narrow
            );
          }
          @include mq($emu-semantic-sizing-breakpoints-xx-large) {
            font: var(
              --emu-component-containers-scroll-reveal-header-assistant-text-typography-wide
            );
          }
        }
        .card {
          .emu-card__title {
            .emu-title {
              &__text {
                font: var(
                  --emu-component-containers-scroll-reveal-cards-header-assistant-text-typography-narrow
                );
                @include mq($emu-semantic-sizing-breakpoints-large) {
                  font: var(
                    --emu-component-containers-scroll-reveal-cards-header-assistant-text-typography-wide
                  );
                }
              }
              h3.emu-title__text {
                font: var(
                  --emu-component-containers-scroll-reveal-header-assistant-text-typography-narrow
                );
                @include mq($emu-semantic-sizing-breakpoints-xx-large) {
                  font: var(
                    --emu-component-containers-scroll-reveal-header-assistant-text-typography-wide
                  );
                }
              }
            }
          }
        }
      }
      &-title__text {
        font: var(
          --emu-component-containers-scroll-reveal-header-assistant-text-typography-narrow
        );
        @include mq($emu-semantic-sizing-breakpoints-large) {
          font: var(
            --emu-component-containers-scroll-reveal-header-assistant-text-typography-wide
          );
        }
      }
    }
  }
}
