.juvederm-3up {
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--emu-semantic-sizing-content-max-width);

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-bottom: 120px;
  }
  .carousel {
    border-bottom: 1px solid var(--emu-semantic-colors-secondary-grey-100);
    border-top: 1px solid var(--emu-semantic-colors-secondary-grey-100);
    padding-bottom: 20px;
    padding-top: 20px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      padding-bottom: 30px;
      padding-top: 30px;
    }
  }

  .tns-inner {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .aaaem-carousel {
    &__content {
      display: flex;
    }

    &__item {
      padding-bottom: 20px;
      padding-top: 20px;
      position: relative;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-bottom: 30px;
        padding-top: 30px;
      }

      &:not(:last-of-type) {
        &::before {
          background-color: var(--emu-semantic-colors-secondary-grey-100);
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          right: 15px;
          top: 0;
          width: 1px;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            right: 30px;
          }
        }
      }
    }
  }

  &.teal-border{
    .carousel {
      border-bottom: 1px solid var(--emu-semantic-colors-text-teal);
      border-top: 1px solid var(--emu-semantic-colors-text-teal);
    }
    .aaaem-carousel {
      &__item {
        &:not(:last-of-type) {
          &::before {
            background-color: var(--emu-semantic-colors-text-teal);
          }
        }
      }
    }
  }

  h4 {
    font-family: var(--emu-semantic-font-families-body);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .cmp-text {
    p,
    span {
      font-size: 16px;
      line-height: 30px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        font-size: 18px;
      }
    }
  }
}
// Country overrides
html[lang='he'],
html[lang='he_il'] {
  .juvederm-3up {
    h4 {
      font-family: var(--emu-semantic-font-families-body-assistant);
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }
}
