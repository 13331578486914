.juvederm-clinic-details-page {
  > .container:first-of-type > .aaaem-container {
    position: relative;
    
    @include mq($emu-semantic-sizing-breakpoints-large) {
      /* necessary to have the correct height and space for the text positioned absolutely next to the ICL Detail */
      min-height: 1800px;
    }
  }

  .icldetail {
    + .text {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-bottom: 90px;
        max-width: 445px;
        position: absolute;
        right: 15px;
        top: 535px;
        width: calc(50% - 45px);

        @include aem-editor-view() {
          position: relative;
          top: unset;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        left: calc(50% + 15px);
        right: auto;

        @include aem-editor-view() {
          left: unset;
        }
      }

      .aaaem-text {
        background-color: var(--emu-semantic-colors-transparent-silver-100);
        margin-top: 10px;
        padding-bottom: 60px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 60px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-top: 0;
          padding-bottom: 30px;
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 30px;
        }

        h4 {
          font-size: 27px;
          font-weight: 400;
          letter-spacing: -0.5px;
          line-height: 35px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          margin-bottom: 20px;
        }

        b {
          display: block;
          font-family: var(--emu-semantic-font-families-heading);
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;

          &::before {
            content: "";
            display: block;
            margin-bottom: 30px;
            margin-top: 30px;
            height: 1px;
            width: 40px;
            background-color: var(--emu-semantic-colors-contrast-purple-200);
          }
        }
      }
    }
  }
  // Country overrides 
  html[lang="he"],
  html[lang="he_il"]{
    .icldetail {
      + .text {
        .aaaem-text {
          b {
            font-family: var(--emu-semantic-font-families-heading-assistant);
          }
        }
      }
    }
  }
}

body {
  &#clinic-details-page,
  &.clinic-details-page {
    padding-bottom: 90px;
    
    @include mq($emu-semantic-sizing-breakpoints-large) {
      padding-bottom: 110px;
    }
  }
}