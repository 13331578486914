.juvederm-treatment-lips-page,
.juvederm-treatment-eye-area-page,
.juvederm-treatment-enhance-page,
.juvederm-treatment-restore-page,
.juvederm-treatment-male-page {
  .juvederm-mediatext {
    margin-top: 60px;
  }

  > div:nth-child(4) {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-bottom: 120px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 140px;
    }
  }
}