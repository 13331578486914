/* class to add arbitrary text components inside modules that don't have a placeholder for plain text */
.juvederm-text {

    margin-left: auto;
    margin-right: auto;
    margin-top: var(--emu-common-spacing-large);
    margin-bottom: var(--emu-common-spacing-large);
    max-width: var(--emu-semantic-sizing-content-max-width);

    p {
        font-size: var(--emu-common-font-sizes-narrow-large);
        line-height: 30px;
    }

}