@keyframes pulse {
  0% {
    box-shadow: 0 2px 14px 0 rgba(0,0,0,.5),0 0 0 0 rgba(125,30,129,.5)
  }

  90% {
    box-shadow: 0 2px 14px 0 rgba(0,0,0,.5),0 0 0 8px rgba(125,30,129,0)
  }

  to {
    box-shadow: 0 2px 14px 0 rgba(0,0,0,.5),0 0 0 0 rgba(125,30,129,0)
  }
}

$graphikFont: Graphik,NeueHaasUnicaW1G-Regular,Helvetica Neue,Helvetica,Arial,sans-serif;
$avgSpacing: 15px;
$headerHeightMob: 60px;
$headerHeightDes: 90px;

#juvederm-phase1:not(.clinics-page) {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    font-size: inherit;
    color: inherit;
  }
}

#juvederm-phase1,
.juvederm-phase1__clinic {
  font-size: 18px;

  html[dir="rtl"] & {
    direction: ltr;

    .juvp1-top-part h1,
    .c-search-location h2 {
      text-align: right;
    }
  }

  div.root > div.aaaem-container {
    background-color: var(--emu-common-colors-white);

    header + .container {
      & > .aaaem-container {
        background-color: var(--emu-common-colors-white);
      }
    }
  }

  // -- FOOTER --
	.emu-navigation__content-wrapper {
    position: static;

    nav {
      background-color: var(--emu-common-colors-transparent);
      padding: 0;
      position: static;

      & > ul {
        @include mq-lg {
          flex-direction: column;
        }
      }
    }

    a {
      line-height: 30px;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 20px;
      margin-left: 0;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: opacity .1s;
      background-color: var(--emu-common-colors-transparent);
      padding: 0;
      border: 0;

      @include mq-lg {
        text-align: left;
        font-size: 14px;
        letter-spacing: .5px;
        line-height: 20px;

        html[dir="rtl"] & {
          text-align: right;
        }
      }

      &:hover {
        opacity: .8;
        background-color: transparent;
      }
    }

    li:last-child a {
      margin-bottom: 0;
    }
  }

  footer.experiencefragment {
    padding: 0;

    p {
      font-size: inherit;
    }

    .upper_section .aem-Grid {
      // top section, MLR
      & > .text {
        background-color: #7d1e81;
        padding-right: $avgSpacing;
        padding-left: $avgSpacing;

        li {
          margin-bottom: 0;
        }

        ol,
        ul {
          margin: 0;
          padding: 0;
          list-style-position: inside;
        }
      }

      & > .container {
        background: linear-gradient(180deg,#7d1e81,var(--emu-semantic-colors-primary-purple-100));
        padding-right: $avgSpacing;
        padding-left: $avgSpacing;

        & + .text {
          background-color: var(--emu-common-colors-transparent);

          .disclaimer-text {
            @include mq-lg {
              margin-right: auto;
              margin-left: auto;
              max-width: 83.3333333333%;
            }

            @include mq-content {
              max-width: 950px;
            }
          }
        }
      }
    }

    .aaaem-button {
      background-color: var(--emu-common-colors-transparent);
      border: 0;
      border-color: var(--emu-common-colors-transparent);
      margin-top: 18px;
      margin-right: 18px;
      margin-bottom: 0;
      margin-left: 18px;
      padding: 0;

      @include mq-lg {
        margin-top: 0;
      }

      &:hover,
      &:focus {
        background-color: var(--emu-common-colors-transparent);
      }
    }

    .juvederm-footer {
      background-color: var(--emu-common-colors-transparent);
      padding: 0;
      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(220,203,227,.2);

      @include mq-lg {
        display: flex;
        justify-content: space-between;
        margin-right: auto;
        margin-left: auto;
        max-width: 83.3333333333%;
      }

      @include mq-content {
        max-width: 950px;
      }

      .text + .container {
        border: 0;
      }

      & > .navigationlist {
        border-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        @include mq-lg {
          margin-top: 0;
          width: 370px;

          html[dir="rtl"] & {
            width: auto;
          }
        }

        .emu-navigation__menu--level-0 {
          display: flex;
          max-width: none;

          @include mq-lg {
            margin-left: 0;
          }

          .emu-navigation__item {
            @include mq-lg {
              padding-left: 0;
            }
          }
        }
      }

      .responsivegrid .cmp-container {
        background: var(--emu-common-colors-transparent);
        display: block;
        padding-bottom: 0;

        &.juvederm-footer-logos {
          text-align: center;

          @include mq-lg {
            border: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }

          & > div {
            margin-bottom: 40px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .disclaimer-text {
      font-family: $emu-semantic-font-families-body;
      font-size: 12px;
      line-height: 1.25;
      text-align: left;
      padding-bottom: 40px;
      padding-top: 40px;

      html[dir="rtl"] & {
        text-align: right;
        direction: rtl;
      }

      p {
        line-height: 1.25;
      }

      p,
      li {
        margin-bottom: $avgSpacing;
        color: hsla(0,0%,100%,.8);
      }
    }

    .juvp1-footer {
      &__top {
        padding-bottom: 40px;
        padding-top: 40px;
        padding-left: 0;
        padding-right: 0;
        border: 0;
        border-bottom: 1px solid rgba(220,203,227,.2);
        margin-top: 0;
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;;

        @include mq-lg {
          max-width: 83.3333333333%;
        }

        @include mq-content {
          max-width: 950px;
        }

        &.disclaimer-text {
          p {
            margin: 0;
          }
        }
      }

      &__social {
        margin: 0;
        padding: 0;
        text-align: center;

        @include mq-lg {
          text-align: right;
        }

        & > .button {
          display: inline-block;
        }
      }
    }
  }
  // -- /END FOOTER --

  // -- HEADER --
	.juvederm-navigation-findaclinic {
    display: none;
  }

  .cmp-languagenavigation__group {
    pointer-events: none;
    top: auto;
    margin-top: 10px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 2px 2px rgba(0,0,0,.02), 0 4px 4px rgba(0,0,0,.02), 0 8px 8px rgba(0,0,0,.02), 0 16px 16px rgba(0,0,0,.02);
    transform: translateY(-20px);

    &.active {
      pointer-events: all;
      transform: translateY(0);
    }
  }

  .cmp-languagenavigation__item-link {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    color: var(--emu-common-colors-black);
    box-shadow: none;
  }

  .cmp-languagenavigation__switcher {
    font-weight: 700;
  }

  header {
    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
    }

    &.header {
      background-color: var(--emu-semantic-colors-primary-purple-100);
      height: $headerHeightMob;

      @include mq-xxlg {
        height: $headerHeightDes;
      }

      &--sticky {
        .js-has-languagenavigation & {
          .languagenavigation {
            display: none;
          }
        }
      }
    }

    .cmp-container-header {
      position: relative;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      display: grid;
      @include mq-xlg {
        padding-top: 0;
        padding-bottom: 0;
        height: 100%;
      }
    }

    .aaaem-button {
      font-size: 12px;

      @include mq-sm {
        font-size: 16px;
      }
    }

    .header__logo {
      width: 100%;
      text-align: center;
      grid-column: 1/1;
      grid-row: 1/1;

      @include mq(767.98px, 'max-width') {
        .js-has-languagenavigation & {
          text-align: left;
        }
      }


      svg {
        @include mq-sm {
          max-width: 145px;
        }

        @include mq-xxlg {
          max-width: 194px;
        }
      }
    }

    .header__content {
      grid-column: 1/1;
      grid-row: 1/1;
    }

    .header__global.navigation {
      display: none;
    }

    &.header--sticky {
      .juvederm-navigation-findaclinic {
        display: block;
      }

      .header__logo {
        width: auto;
        text-align: left;

        @include mq-lg {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .juvederm-header-burger {
    display: none;
  }

  .juvederm-navigation--desktop {
    display: flex;
  }

  .juvederm-navigation {
    padding: 0;
  }

  .juvederm-navigation-wrapper-bottom {
    background-color: var(--emu-common-colors-transparent);
    grid-gap: 30px;
    border-top: 0;
    gap: 30px;
    padding-top: 0;
    display: flex;

    @include mq-xlg {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-20px);
    }
  }

	.juvederm-phase1__content > .aaaem-container {
		background-color: var(--emu-common-colors-white);
	}
  // -- /END HEADER --

  // -- GLOBALS --
  .juvp1-top-part {
    color: var(--emu-semantic-colors-primary-purple-100);
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: $headerHeightMob;

    @include mq-xxlg {
      margin-top: $headerHeightDes;
    }

    & > div {
      max-width: var(--emu-semantic-sizing-content-max-width);
      align-items: center;
      padding-top: 10px;
      padding-right: $avgSpacing;
      padding-bottom: 10px;
      padding-left: $avgSpacing;

      @include mq-xlg {
        padding: 0;
        margin-top: 0;
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;;
      }
    }

    & > div.aem-Grid {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      @include mq-lg {
        flex-wrap: nowrap;

        .image:not(.aaaem-image):last-child,
        .text:not(.aaaem-text) {
          flex: 0 0 50%;
          max-width: 50%;
          padding-right: $avgSpacing;
          padding-left: $avgSpacing;
        }
      }

      & > .image {
        order: 1;

        @include mq-lg {
          order: 2;
        }

        img {
          margin: auto;
        }
      }

      & > .text {
        order: 2;

        @include mq-lg {
          display: flex;
        }

        .aaaem-text {
          padding-top: 40px;
          padding-bottom: 40px;
          padding-right: $avgSpacing;
          padding-left: $avgSpacing;

          @include mq-lg {
            margin: auto;
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 30px;

            p {
              margin-bottom: 40px;
            }
          }
        }
      }
    }

    &.juvp1-top-part--noPadding {
      & > div.aem-Grid {
        @include mq-xxlg {
          .image:not(.aaaem-image):last-child,
          .text:not(.aaaem-text) {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }

      & .juvp1-image__desktop {
        & .cmp-image__image {
          margin-left: auto;
          margin-right: unset;
        }
      }
    }

    &.js-emu-inview {
      h1,
      p {
        opacity: 0;
        animation-duration: .8s;
        animation-fill-mode: forwards;
        animation-name: slideIn;
      }
    }

    .title {
      padding-top: 0;
      padding-bottom: 0;
    }

    h1 {
      font-size: 40px;
      line-height: 45.2px;
      letter-spacing: -1px;
      animation-delay: .3s;

      @include mq-xxlg {
        font-size: 65px;
        letter-spacing: -2px;
        line-height: 70.2px;
      }
    }

    h2 {
      font-size: 40px;
      font-weight: 400;
      line-height: 45px;
      letter-spacing: -1px;
      margin-bottom: 30px;

      @include mq-xxlg {
        font-size: 50px;
        line-height: 55px;
      }
    }

    p {
      line-height: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 400;
      animation-delay: .8s;

      @include mq-xxlg {
        font-size: 25px;
        line-height: 35px;
        font-weight: 300;
        margin-top: 40px;
      }
    }
  }

  .aaaem-carousel__actions {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    padding-right: $avgSpacing;
    padding-left: $avgSpacing;
    top: 50%;
    transform: translateY(12px);
    z-index: var(--emu-common-other-z-index-layer);

    @include mq-lg {
      width: 33.3333333333%;
      justify-content: flex-start;
      right: 0;
      padding-left: 20px;
    }
  }

  .aaaem-carousel__action {
    border-radius: 25px;
    background-color: transparent;
    border: 1px solid rgba(71,13,74,0.2);
    color: #800080;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 400;
    height: 50px;
    line-height: 3rem;
    min-width: 50px;
    overflow: hidden;
    padding-top: 8px;
    padding-right: 20px;
    padding-bottom: 12px;
    padding-left: 20px;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: .3s;
    white-space: nowrap;
    width: 50px;

    &-icon {
      display: flex;
      align-items: center;
      width: 39px;
      height: 100%;
      position: absolute;
      top: 0;

      &:before {
        content: '';
        display:block;
        width: 10px;
        height: 1px;
        background-color: var(--emu-common-colors-black);
      }
      svg {
        width: 14px;
      }
    }

    &-next {
      @include mq-lg {
        margin-left: $avgSpacing;
      }

      .aaaem-carousel__action-icon {
        left: 19px;
      }

      svg {
        margin-left: -8px;
      }
    }

    &-prev {
      svg {
        margin-left: -16px;
      }
    }

    &:hover,
    &:focus {
      background-color: rgba(71,13,74,0.2);
      border-color: rgba(71,13,74,0.2);
    }
  }

	.aaaem-button {
		font-size: 18px;
		font-weight: 400;
		padding-top: 8px;
    padding-right: 20px;
    padding-bottom: 12px;
    padding-left: 20px;
		position: relative;
		text-decoration: none;
		text-overflow: ellipsis;
		color: var(--emu-semantic-colors-primary-purple-100);
		background-color: var(--emu-common-colors-white);
		transition: .3s;
		white-space: nowrap;

		&:hover,
		&:focus {
			background-color: #ede5f1;
			border-color: #ede5f1;
		}
	}

	p {
		font-family: $graphikFont;
	}

  .emu-colors-bg__dark {
		background-color: var(--emu-semantic-colors-primary-purple-100) !important;
	}

	.emu-colors-bg__light {
		background-color: var(--emu-semantic-colors-contrast-purple-600) !important;
	}

	.juvp1-image__desktop {
		display: none;
	}

	@include mq-lg {
		.juvp1-image__desktop {
			display: block;
		}

		.juvp1-image__mobile {
			display: none;
		}
	}
}

// Page specific styles
#juvederm-phase1 {
  // Hide MLR codes on all pages but homepage.
  &.juvederm-phase1__secondary,
  &.juvederm-phase1__fac,
  &.clinics-page {
    footer.experiencefragment {
      .upper_section .aem-Grid {
        // top section, MLR
        & > .text:first-child {
          display: none;
        }
      }
    }
  }

  .locationservices .emu-location-services {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    background-color: var(--emu-common-colors-transparent);

    &__location-null-error,
    &__location-input-error {
      position: absolute;
      font-weight: 500;
    }

    &.js-has-location-null-error,
    &.js-has-location-input-error {
      .emu-location-services__location-input {
        border: 2px solid var(--emu-semantic-colors-error);
        background-color: #fef8f6;
      }
    }

    &.js-has-location-null-error {
      .emu-location-services__location-input-error {
        display: none;
      }

      .emu-location-services__location-null-error {
        display: block;
      }
    }

    &.js-has-location-input-error {
      .emu-location-services__location-null-error {
        display: none;
      }

      .emu-location-services__location-input-error {
        display: block;
      }
    }
  }

  // Homepage specific
  &.juvederm-phase1__home {
    .juvp1-treatments,
    .juvp1-treatment-areas,
    .juvp1-clinic-tout,
    .juvp1-key-features,
    .juvp1-journey,
    .juvp1-clinic-locator {
      color: var(--emu-semantic-colors-primary-purple-100);
      padding-top: 60px;
      padding-bottom: 60px;

      & > div {
        max-width: var(--emu-semantic-sizing-content-max-width);
        align-items: center;
        padding-top: 10px;
        padding-right: $avgSpacing;
        padding-bottom: 10px;
        padding-left: $avgSpacing;

        @include mq-xlg {
          padding: 0;
          margin-top: 0;
          margin-right: auto;
          margin-bottom: 0;
          margin-left: auto;;
        }
      }

      .title {
        padding-top: 0;
        padding-bottom: 0;
      }

      h2 {
        font-size: 40px;
        font-weight: 400;
        line-height: 45px;
        letter-spacing: -1px;
        margin-bottom: 30px;

        @include mq-xxlg {
          font-size: 50px;
          line-height: 55px;
        }
      }
    }

    .juvp1-treatments {
      padding-top: 40px;

      & > div {
        padding-top: 0;
        padding-bottom: 0;

        &.carousel {
          padding: 0;
        }
      }

      &.js-emu-inview {
        & > div {
          opacity: 0;
          animation-duration: .8s;
          animation-fill-mode: forwards;
          animation-name: slideIn;
          animation-delay: 1.1s;
        }
      }

      @include mq-lg {
        margin-top: -100px;
        padding-top: 110px;
      }

      @include mq-xlg {
        margin-top: -80px;
      }

      @include mq-xxlg {
        margin-top: -120px;
        padding-top: 140px;
      }

      &.juvp1-treatments--noMargin {
        margin-top: 0;
        padding-top: 40px;

        & .juvp1-treatments__carousel {
          @include mq-xxlg {
            padding: 0;
          }
        }
      }

      .juvp1-treatments__carousel {
        @include mq-lg {
          padding-right: $avgSpacing;
          padding-left: $avgSpacing;
        }



        .tns-slide-active {
          .juvp1-treatments__carousel--text {
            opacity: 1;
          }
        }

        &.js-slide-change-start {
          .tns-slide-active {
            .juvp1-treatments__carousel--text {
              animation-delay: .5s;
              animation-duration: .8s;
              animation-fill-mode: both;
              animation-name: fadeIn;
              transition: none;
            }
          }
        }

        &-footnote {
          margin-top: 40px;
          padding-right: $avgSpacing;
          padding-left: $avgSpacing;

          @include mq-lg {
            padding-left: 0;
          }

          p {
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
            color: #58585a;
          }
        }

        &--text {
          margin-top: 20px;
          text-align: center;
          padding-right: $avgSpacing;
          padding-left: $avgSpacing;
          opacity: 0;

          @include mq-lg {
            text-align: left;
            padding-left: 30px;
            margin-top: 30px;
          }

          @include mq-xxlg {
            margin-top: 52px;
            max-width: 455px;
          }

          h3 {
            font-size: 25px;
            letter-spacing: -0.5px;
            line-height: 30px;
            max-width: 75%;
            margin-top: 0;
            margin-right: auto;
            margin-bottom: 0;
            margin-left: auto;;
            font-weight: 400;
            font-family: $graphikFont;

            @include mq-lg {
              max-width: none;
            }

            @include mq-xxlg {
              font-size: 40px;
              line-height: 45px;
              letter-spacing: -1px;
            }

            & + p {
              margin-top: 28px;
              line-height: 30px;
              font-weight: 400;
              color: var(--emu-common-colors-black);

              @include mq-lg {
                font-size: 14px;
                line-height: 20px;
                margin-top: 10px;
                letter-spacing: .64px;
              }

              @include mq-xxlg {
                margin-top: $avgSpacing;
                font-size: 18px;
              }
            }
          }
        }

        &--image-comparison {
          background-color: var(--emu-common-colors-transparent);

          .emu-image {
            padding-top: 155.37%;
            position: relative;
            width: 100%;
            background-color: var(--emu-common-colors-white);

            @include mq-lg {
              padding-top: 123.37%;
            }

            img {
              height: auto;
              left: 0;
              -o-object-fit: cover;
              object-fit: cover;
              position: absolute;
              bottom: 0;
              width: 100%;

              @include mq-lg {
                height: 100%;
                top: 0;
                bottom: auto;
              }
            }
          }

          .emu-image__title {
            font-size: 14px;
            left: 20px;
            letter-spacing: 1px;
            line-height: 15px;
            position: absolute;
            text-transform: uppercase;
            top: 17px;
            color: #58585a;
            font-family: $graphikFont;
            // z-index: 5;
          }

          .aem-Grid {
            display: grid;
            grid-gap: 5px;
            grid-template-columns: 1fr 1fr;

            @include mq-lg {
              grid-gap: 10px;
            }

            & > div {
              width: 100%;
            }

            &:before,
            &:after {
              display: none;
            }
          }
        }

        .aaaem-carousel__item-container {
          @include mq-lg {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 66.6666666667% 33.3333333333%;

            .container {
              grid-column: 1;
            }
          }
        }
      }
    }

    .juvp1-treatment-areas {
      background-color: var(--emu-common-colors-white);

      @include mq-lg {
        h2 {
          margin-bottom: 60px;
        }
      }

      &__items {
        @include mq-xxlg {
          margin-top: 0;
          height: 100%;
        }

        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        & > .text {
          grid-column: 1/1;
          grid-row: 1/1;
          opacity: 0;
          transition: .2s;
          visibility: hidden;

          &[aria-expanded="true"] {
            opacity: 1;
            visibility: visible;
            z-index: 1;
          }
        }
      }

      .tabs {
        padding-top: 0;
        padding-bottom: 0;
      }

      .emu-tabs {
        margin-top: 0;
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;;
        background-color: var(--emu-common-colors-transparent);
        max-width: 384px;

        @include mq-xxlg {
          max-width: 83.3333333333%;
        }
      }

      .aaaem-tabs__tablist {
        @include mq-xxlg {
          max-width: calc(50% - $avgSpacing);
        }
      }

      .aaaem-tabs__tab {
        flex: 0 0 50%;
        text-align: center;
        margin: 0;

        a {
          flex: 0 0 50%;
          border-width: 0;
          color: rgba(0,0,0,.4);
          cursor: pointer;
          display: block;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1px;
          padding-top: 12px;
          padding-right: 0;
          padding-bottom: 12px;
          padding-left: 0;
          line-height: 15px;
          position: relative;
          text-transform: uppercase;
          transition: .5s;
          background-color: var(--emu-common-colors-transparent);

          &:before,
          &:after {
            content: "";
            display: block;
            height: 2px;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            background-color: #ede5f1;
            will-change: transform;
          }

          &:after {
            background-color: #7d1e81;
            transform: scaleX(0);
            transform-origin: center left;
            transition: .5s;
          }

          &[aria-selected="true"] {
            color: var(--emu-common-colors-black);

            &:after {
              transform: scaleX(1);
            }
          }
        }

        &:nth-child(odd) a:after {
          transform-origin: center right;
        }
      }

      .aaaem-tabs__tabpanel .aaaem-container {
        background-color: var(--emu-common-colors-transparent);

        .aaaem-text {
          @include mq-xxlg {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 25%;
          }

          ul {
            font-size: 18px;
            line-height: 24px;
            list-style-position: inside;
            list-style: none;
            margin: 0;
            padding: 0;
          }

          li {
            margin-top: 5px;
            margin-right: 0;
            margin-bottom: 5px;
            margin-left: 0;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 10px;
            position: relative;
            font-family: $graphikFont;
            color: var(--emu-common-colors-black);

            &:before {
              content: "·";
              left: 0;
              line-height: inherit;
              position: absolute;
              vertical-align: middle;
            }
          }
        }

        h3 {
          color: var(--emu-semantic-colors-primary-purple-100);
          font-size: 25px;
          letter-spacing: -.5px;
          line-height: 30px;
          margin: 0;

          @include mq-xxlg {
            font-size: 40px;
            letter-spacing: -1px;
            line-height: 45px;
            margin-bottom: 20px;
          }
        }

        .image {
          width: 100%;

          @include mq-xxlg {
            width: 520px;
          }
        }

        @include mq-xxlg {
          & > .aem-Grid {
            display: flex;
          }
        }
      }

      .emu-mapping-marker {
        background-color: var(--emu-common-colors-white);
        border: none;
        border-radius: 50%;
        box-shadow: 0 2px 14px 0 rgb(0 0 0 / 50%);
        cursor: pointer;
        display: block;
        height: 27px;
        padding: 0;
        transition: .2s;
        width: 27px;

        span {
          &:before,
          &:after {
            background-color: var(--emu-semantic-colors-primary-purple-100);
          }
        }

        &[aria-pressed="true"] {
          animation: pulse 2.5s infinite;
          background-color: #7d1e81;
          box-shadow: 0 2px 14px 0 rgb(0 0 0 / 50%), 0 0 0 0 #7d1e81;

          span {
            &:before,
            &:after {
              background-color: var(--emu-common-colors-white);
            }
          }
        }


      }
    }

    .juvp1-clinic-tout {
      padding-right: $avgSpacing;
      padding-left: $avgSpacing;
      background-color: #7d1e81;
      color: var(--emu-common-colors-white);
      text-align: center;

      & > div {
        padding: 0;
      }

      .aaaem-text {
        margin-top: 0;
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;;
        max-width: 610px;

        @include mq-xlg {
          max-width: 810px;
        }
      }

      h2 {
        font-size: 35px;
        line-height: 1.145;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 20px;
        margin-left: 0;
        letter-spacing: -1px;

        @include mq-xlg {
          font-size: 40px;
        }
      }

      p {
        line-height: 1.67;
      }

      .aaaem-button {
        margin-top: 40px;
        width: 100%;
        justify-content: center;

        @include mq-sm {
          width: auto;
        }
      }
    }

    .juvp1-key-features {
      background-color: var(--emu-common-colors-white);
      display: flex;
      flex-direction: column;

      @include mq-lg {
        padding-bottom: 90px;

        .title,
        & > .container {
          margin-right: 8.3333333333%;
          margin-left: 8.3333333333%;
          max-width: var(--emu-semantic-sizing-content-max-width);
        }
      }

      @include mq-xlg {
        padding-bottom: 120px;
        padding-top: 90px;

        .title,
        & > .container {
          margin-top: 0;
          margin-right: auto;
          margin-bottom: 0;
          margin-left: auto;;
          padding-right: $avgSpacing;
          padding-left: $avgSpacing;
          width: 100%;
        }
      }

      .container {
        padding-top: 0;
        padding-bottom: 0;
      }

      h2 {
        font-size: 35px;
        letter-spacing: -1px;
        line-height: 1.145;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 40px;
        margin-left: 0;

        @include mq-xlg {
          font-size: 40px;
        }
      }

      &__grid {
        background-color: var(--emu-common-colors-white);

        .aem-Grid {
          &:before,
          &:after {
            display: none;
          }

          @include mq-sm {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 20px;
            grid-column-gap: 30px;
          }

          @include mq-lg {
            grid-row-gap: 30px;
            grid-column-gap: 30px;
          }

          @include mq-xlg {
            display: flex;
          }

          & > .button {
            @include mq($emu-semantic-sizing-breakpoints-x-large, 'max-width') {
              width: 100%;
            }
          }
        }
      }

      &__card-button {
        width: 100%;
        align-items: center;
        background-color: #ede5f1;
        border: 1px solid rgba(125,30,129,.15);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: 40px;
        padding-right: 10px;
        padding-bottom: 30px;
        padding-left: 10px;
        position: relative;
        text-align: center;
        border-radius: 0;
        font-family: $graphikFont;
        white-space: break-spaces;
        margin-bottom: 20px;
        position: relative;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;

        svg {
          margin-top: 0;
          margin-right: auto;
          margin-bottom: 0;
          margin-left: auto;;
          min-height: 60px;
          width: 60px;
        }


        & > span {
          margin-top: 20px;
          margin-right: 3px;
          margin-bottom: 10px;
          margin-left: 3px;
          display: flex;
          height: inherit;
          align-items: center;

          @include mq-lg {
            margin-top: 20px;
            margin-right: 13px;
            margin-bottom: 10px;
            margin-left: 13px;
          }

          &:after,
          &:before {
            content: "";
            background-color: var(--emu-semantic-colors-primary-purple-100);
            width: 10px;
            height: 1px;
            position: absolute;
            bottom: 19px;
            right: 14px;
          }

          &:before {
            transform: rotate(90deg);
          }
        }

        &:after {
          content: "";
          border: 1px solid rgba(71,13,74,.2);
          border-radius: 50%;
          bottom: 10px;
          color: var(--emu-semantic-colors-primary-purple-100);
          display: block;
          height: 19px;
          position: absolute;
          right: 10px;
          width: 19px;
        }

        &:hover {
          border: 1px solid rgba(125,30,129,.15);
        }
      }

      &__modal {
        overflow: hidden;

        .modal-wrapper {
          background-color: var(--emu-common-colors-transparent);
          transition: .2s background-color;

          &.visible {
            background-color: rgba(0,0,0,.4);

            .modal-content {
              transform: translateY(0);

              @include mq-lg {
                opacity: 1;
                transform: translate(-50%,-50%);
              }
            }
          }

          &.is-open,
          &.active {
            display: block;
          }
        }

        .modal-content {
          width: 100%;
          margin: 0;
          margin-top: auto;
          position: fixed;
          bottom: 0;
          transition: .2s transform, .2s opacity;
          transform: translateY(100%);
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          left: 0;
          padding-top: 20px;
          padding-right: 14px;
          padding-bottom: 60px;
          padding-left: 14px;

          @include mq-lg {
            bottom: auto;
            left: 50%;
            opacity: 0;
            padding-top: 20px;
            padding-right: 20px;
            padding-bottom: 60px;
            padding-left: 20px;
            right: auto;
            top: 50%;
            transform: translate(-50%,-50%) scale(.9);
            width: 610px;
            text-align: center;
            align-items: center;
          }

          @include mq-xlg {
            padding-top: 20px;
            padding-right: 20px;
            padding-bottom: 90px;
            padding-left: 20px;
            width: 730px;
          }

          .aaaem-container {
            background-color: var(--emu-common-colors-white);

            @include mq-lg {
              line-height: 1.7;
              margin-top: 0;
              margin-right: auto;
              margin-bottom: 0;
              margin-left: auto;;
              max-width: 350px;
            }
          }

          h3 {
            color: var(--emu-semantic-colors-primary-purple-100);
            font-size: 25px;
            font-weight: 400;
            letter-spacing: -.5px;
            line-height: 1.2;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: left;

            @include mq-lg {
              font-size: 35px;
              letter-spacing: -1px;
              line-height: 1.15;
              text-align: center;
            }

            @include mq-xlg {
              font-size: 30px;
              letter-spacing: -.5px;
              line-height: 1.17;
              margin-top: 50px;
            }
          }

          p {
            line-height: 30.6px;
            color: var(--emu-common-colors-black);
          }

          .close {
            position: static;
            padding: 0;
            font-size: 31px;
            line-height: 16px;

            @include mq-lg {
              margin-left: auto;
            }
          }
        }
      }
    }

    .juvp1-journey {
      background-color: var(--emu-common-colors-white);

      @include mq-lg {
        padding-top: 10px;
      }

      @include mq-xxlg {
        padding-top: 60px;
        padding-bottom: 90px;
      }

      h2 {
        font-size: 35px;
        line-height: 1.145;
        margin: 0;

        @include mq-lg {
          margin-bottom: 25px;
        }

        @include mq-xxlg {
          font-size: 50px;
          letter-spacing: -1.25px;
          line-height: 1.1;
          margin-bottom: 60px;
        }
      }

      & > .teaser {
        padding: 0;

        @include mq-lg {
          &:nth-child(odd) .teaser-container {
            flex-direction: row-reverse;
          }
        }

        @include mq-lg {
          margin-bottom: -15px;
        }

        @include mq-xxlg {
          margin-top: 40px;
          margin-bottom: 15px;
        }
      }

      &__tout {
        margin-top: 30px;
        height: auto;

        @include mq-lg {
          margin-top: 5px;
          margin-bottom: 0;
        }

        &:after {
          display: none;
        }

        p {
          line-height: 1.67;
          margin-top: 10px;
          margin-right: 0;
          margin-bottom: 0;
          margin-left: 0;
        }

        .content {
          max-width: none;
        }

        .teaser-container {
          display: flex;
          flex-direction: column;

          @include mq-lg {
            align-items: center;
            flex-direction: row;
          }
        }

        .aaaem-teaser__image {
          margin-top: 10px;
          margin-bottom: 10px;
          position: relative;

          @include mq-lg {
            margin-top: $avgSpacing;
            margin-right: $avgSpacing;
            margin-bottom: $avgSpacing;
            margin-left: $avgSpacing;
            flex-basis: 59.04%;
          }

          @include mq-content {
            .emu-image {
              min-height: 340px;
            }
          }

          @include mq-xxlg {
            flex-basis: 57.208%;
            margin: 0;
          }
        }

        .aaaem-teaser__title {
          color: var(--emu-semantic-colors-primary-purple-100);
          font-size: 25px;
          letter-spacing: -.5px;
          line-height: 1.2;
          margin: 0;

          @include mq-lg {
            margin-bottom: 20px;
          }

          @include mq-xxlg {
            font-size: 30px;
            line-height: 1.17;
          }
        }

        .content-container {
          padding-top: 10px;
          padding-right: $avgSpacing;
          padding-bottom: 10px;
          padding-left: $avgSpacing;

          .title-lockup {
            max-width: none;
          }

          @include mq-lg {
            margin-top: $avgSpacing;
            margin-bottom: $avgSpacing;
            flex-basis: 40.96%;
            padding: 0;
            margin-right: 10px;
            margin-left: 10px;
          }

          @include mq-xxlg {
            flex-basis: 42.792%;
            margin: 0;
            padding-top: 0;
            padding-right: 48px;
            padding-bottom: 0;
            padding-left: 77px;
          }
        }

        .cmp-teaser__description {
          padding: 0;
        }
      }
    }

    .juvp1-clinic-locator {
      background-color: var(--emu-semantic-colors-contrast-purple-100);
      margin-top: 30px;

      @include mq-lg {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      h2 {
        margin-bottom: 20px;
        @include mq-lg {
          margin-bottom: 40px;
        }

        @include mq-xxlg {
          font-size: 55px;
          letter-spacing: -1.25px;
          line-height: 55px;
        }
      }

      &__form {
        padding-right: $avgSpacing;
        padding-left: $avgSpacing;
        margin-bottom: 60px;

        @include mq-lg {
          padding-top: 40px;
          padding-bottom: 40px;
          margin-bottom: 0;
          padding-right: 30px;
          padding-left: 0;
          justify-content: center;
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .emu-location-services {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          margin: 0;
          background-color: var(--emu-common-colors-transparent);

          &__location-input {
            background-color: var(--emu-common-colors-white);
            border: 1px solid rgba(71,13,74,.2);
            border-bottom-color: var(--emu-semantic-colors-primary-purple-100);
            color: var(--emu-common-colors-black);
            font-size: 16px;
            width: 100%;

            &:focus {
              background-color: #ede5f1;
              border: 2px solid var(--emu-semantic-colors-primary-purple-100);
            }

            &::placeholder {
              color: rgba(0,0,0,.4);
              font-size: 18px;
              font-stretch: normal;
              font-style: normal;
              font-weight: 400;
              letter-spacing: normal
            }
          }

          label {
            width: auto;
            height: auto;
            position: static;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 1px;
            line-height: 1.08;
            color: var(--emu-semantic-colors-primary-purple-100);
            margin-bottom: 8px;
            display: block;
            margin-left: 2px;
            margin-top: 2px;
            font-family: $graphikFont;
            white-space: normal;

            @include mq-lg {
              font-size: 12px;
              line-height: 15px;
            }
          }

          &__form {
            order: 2;
            margin-bottom: 0;
            width: 100%;

            @include mq-xlg {
              flex-direction: row;
              align-items: flex-end;
            }
          }

          &__location-input-wrapper {
            margin: 0;

            @include mq-xlg {
              max-width: 63.5%;
              width: 100%;
            }
          }

          &__current-location-btn {
            @extend .aaaem-button;
            background-color: var(--emu-semantic-colors-primary-purple-100);
            border: 1px solid var(--emu-semantic-colors-primary-purple-100);
            border-radius: 25px;
            color: var(--emu-common-colors-white);
            position: relative;
            padding-right: 64px;
            margin-bottom: 40px;
            text-overflow: ellipsis;
            overflow: hidden;

            &:before {
              content: "";
              border: 1px solid hsla(0,0%,100%,0.2);
              border-radius: 50px;
              display: inline-block;
              height: 39px;
              padding-top: 10px;
              padding-right: 10px;
              padding-bottom: 10px;
              padding-left: 10px;
              position: absolute;
              right: 5px;
              top: 5px;
              width: 39px;
            }

            &:after {
              content: "";
              background-image: url("data:image/svg+xml;base64,CTxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iTG9jYXRpb24iIHZpZXdCb3g9IjAgMCAxOSAxOSI+CgkJPHRpdGxlPkljb25zL01hcC9Mb2NhdGlvbjwvdGl0bGU+CgkJPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgoJCQk8ZyBpZD0iSWNvbnMvTWFwL0xvY2F0aW9uIiBzdHJva2U9IiNmZmYiPgoJCQkJPHBhdGggZD0iTTE2LjEwMjQ4ODUsMi44OTc1Mjc0NyBMMTAuMzYwNjU2NSwxNy4yNTI2MzgxIEw4LjkyNTAzNzkxLDEwLjA3NTA3NTcgTDEuNzQ3Mzg5Myw4LjYzOTY1MjA1IEwxNi4xMDI0ODg1LDIuODk3NTI3NDcgWiIgaWQ9IlBhdGgiLz4KCQkJPC9nPgoJCTwvZz4KCTwvc3ZnPg==");
              width: 17px;
              display: block;
              height: 17px;
              position: absolute;
              right: 16px;
              top: 16px;
            }

            &:hover,
            &:focus {
              background-color: #38093f;
              border-color: #38093f;
            }
          }

          &__search-submit-cta {
            @extend .aaaem-button;
            visibility: hidden;
            width: 0;
            height: 0;
            overflow: hidden;
            position: absolute;
            top: -9999em;
            left: -9999em;
            background-color: var(--emu-common-colors-white);
            text-transform: capitalize;
            min-width: 22.22%;
            border-color: rgba(71,13,74,0.2);

            &:hover,
            &:focus {
              background-color: rgba(71,13,74,0.2);
              border-color: rgba(71,13,74,0.2);
            }

            @include mq-xlg {
              position: static;
              top: 0;
              left: 0;
              visibility: visible;
              height: auto;
              width: auto;
              overflow: visible;
            }
          }
        }
      }

      .juvp1-container__inner {
        display: flex;
        background-color: var(--emu-common-colors-transparent);
        flex-direction: column-reverse;

        @include mq-lg {
          flex-direction: row-reverse;
          padding-right: $avgSpacing;
          padding-left: $avgSpacing;

          & > .image {
            display: none;
            img {
              width: 100%;
              margin: auto;
            }
          }

          & > .image + .image,
          & > .container {
            display: block;
            flex: 0 0 50%;
          }
        }

        .aaaem-container {
          background-color: var(--emu-common-colors-transparent);
        }
      }

      .aem-Grid {
        display: flex;
        padding: 0;
      }
    }
  }

  & .emu-embed--containerStyle {
    max-width: var(--emu-semantic-sizing-content-max-width);
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;

    @include mq-xxlg {
      padding: 0;
    }
  }

  & .embed:has(.emu-embed--containerStyle) {
    background-color: #ece3ed;
  }

  // Contact, Terms etc...
  &.juvederm-phase1__secondary {
    .root > .aaaem-container > .aem-Grid {
      display: flex;
      height: 100vh;
      flex-wrap: wrap;

      @include aem-editor-view() {
        height: auto;
      }

      & > footer.experiencefragment {
        margin-top: auto;
      }
    }

    .juvp1-general-container {
      background-color: var(--emu-common-colors-white);
      padding-bottom: 90px;
      padding-top: 60px;
      margin-top: $headerHeightMob;

      @include mq-xxlg {
        padding-top: 90px;
        margin-top: $headerHeightDes;
      }

      html[dir="rtl"] & {
        direction: rtl;
        text-align: right;
      }

      p {
        line-height: 1.67;
        margin-top: 20px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          display: inline-block;
        }
      }

      .aaaem-button {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
        border-color: var(--emu-component-actions-button-color-border-primary-filled-dark);
        border-width: var(--emu-component-actions-button-border-width-primary-filled);
        color: var(--emu-component-actions-button-text-color-primary-filled-dark);

        &:hover,
        &:focus {
          background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
          border-color: var(--emu-component-actions-button-color-border-primary-filled-dark);
          border-width: var(--emu-component-actions-button-border-width-primary-filled);
          color: var(--emu-component-actions-button-text-color-primary-filled-dark);
        }
      }

      & > div {
        padding-right: $avgSpacing;
        padding-left: $avgSpacing;

        @include mq-lg {
          max-width: 83.33333333%;
          margin-top: 0;
          margin-right: auto;
          margin-bottom: 0;
          margin-left: auto;;
        }

        @include mq-content {
          max-width: 950px;
          padding-left: 0;
          padding-right: 0;
        }

        &.title {
          max-width: 760px;
        }

        &.button {
          text-align: center;
          margin-top: 45px;
        }
      }

      h4 {
        color: var(--emu-semantic-colors-primary-purple-100);
        font-size: 25px;
        letter-spacing: -.5px;
        line-height: 1.2;
        margin-bottom: 0;
        margin-top: 40px;

        @include mq-xxlg {
          font-size: 30px;
          line-height: 1.17;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      h1 {
        font-size: 50px;
        word-wrap: break-word;
        margin-bottom: 60px;
        margin-top: 0;
        color: var(--emu-semantic-colors-primary-purple-100);
        letter-spacing: -1.5px;
        line-height: 1.1;

        @include mq-lg {
          max-width: 66.6666666667%;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq-xxlg {
          margin-bottom: 90px;
        }
      }

      h2 {
        word-wrap: break-word;
        color: var(--emu-semantic-colors-primary-purple-100);
        letter-spacing: -1px;
        line-height: 1.13;
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 30px;

        @include mq-lg {
          font-size: 50px;
        }
      }
    }

    .aaaem-text {
      a {
        font-weight: 900;
        text-decoration: underline;
      }

      ul {
        list-style: none;
        font-size: 18px;
        line-height: 24px;
        list-style-position: inside;
        margin: 0;
        padding: 0;

        li {
          font-family: $graphikFont;
          line-height: 1.67;
          margin: 5px 0;
          padding: 0 0 0 10px;
          position: relative;

          &:before {
            content: "·";
            left: 0;
            line-height: inherit;
            position: absolute;
            vertical-align: middle;
          }
        }
      }
    }
  }

  // Find a clinic
  &.juvederm-phase1__fac {
    background-color: var(--emu-common-colors-white);

    h2 {
      font-size: 40px;
      font-weight: 400;
      line-height: 45px;
      letter-spacing: -1px;
      margin-bottom: 30px;

      @include mq-xxlg {
        font-size: 50px;
        line-height: 55px;
      }
    }

    header.header--sticky {
      .header__logo {
        text-align: center;
      }

      .juvederm-navigation-findaclinic {
        display: none;
      }
    }

    .juvp1-top-part {
      div.aem-Grid {
        position: relative;

        > .image {
          // @include mq-lg {
          //   min-height: 451px;
          //   max-height: 451px;
          // }

          // @include mq-content {
          //   max-height: 585px;
          //   min-height: 585px;
          // }

          &:not(.aaaem-image):last-child {
            @include mq-xlg {
              flex: 0 0 41.6666666667%;
              max-width: 41.6666666667%;
            }
          }
        }

        & > .text:not(.aaaem-text) {
          @include mq-lg {
            margin-top: 30px;
            margin-bottom: 30px;
            flex-direction: column;

            .aaaem-text {
              padding-right: 0;
              padding-bottom: 0;
            }
          }

          @include mq-xlg {
            flex: 0 0 58.3333333333%;
            max-width: 58.3333333333%;
          }
        }
      }

      .emu-location-services {
        align-items: center;
        display: flex;
        flex-basis: auto;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0;
        margin-bottom: 40px;
        max-width: 541px;

        @include mq-lg {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          background-color: var(--emu-common-colors-white);
          flex-grow: 1;
          height: 1px;
          margin-right: 15px;
          opacity: .3;
        }

        &__current-location-btn {
          @extend .aaaem-button;
          background-color: var(--emu-common-colors-white);
          border: 1px solid var(--emu-common-colors-white);
          border-radius: 25px;
          color: var(--emu-semantic-colors-primary-purple-100);
          position: relative;
          height: 50px;
          padding-right: 64px;
          text-overflow: ellipsis;
          overflow: hidden;

          &:before {
            content: "";
            border: 1px solid rgba(71,13,74,0.2);
            border-radius: 50px;
            display: inline-block;
            height: 39px;
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            position: absolute;
            right: 5px;
            top: 5px;
            width: 39px;
          }

          &:after {
            content: "";
            background-image: url("data:image/svg+xml;base64,CTxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iTG9jYXRpb24iIHZpZXdCb3g9IjAgMCAxOSAxOSI+CgkJPHRpdGxlPkljb25zL01hcC9Mb2NhdGlvbjwvdGl0bGU+CgkJPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgoJCQk8ZyBpZD0iSWNvbnMvTWFwL0xvY2F0aW9uIiBzdHJva2U9IiM0NzBkNGEiPgoJCQkJPHBhdGggZD0iTTE2LjEwMjQ4ODUsMi44OTc1Mjc0NyBMMTAuMzYwNjU2NSwxNy4yNTI2MzgxIEw4LjkyNTAzNzkxLDEwLjA3NTA3NTcgTDEuNzQ3Mzg5Myw4LjYzOTY1MjA1IEwxNi4xMDI0ODg1LDIuODk3NTI3NDcgWiIgaWQ9IlBhdGgiLz4KCQkJPC9nPgoJCTwvZz4KCTwvc3ZnPg==");
            width: 17px;
            display: block;
            height: 17px;
            position: absolute;
            right: 16px;
            top: 16px;
          }

          &:hover,
          &:focus {
            background-color: #ede5f1;
            border-color: #ede5f1;
          }
        }
      }

      .locationservices {
        opacity: 0;
        order: 3;
        animation-duration: .8s;
        animation-fill-mode: forwards;
        animation-name: slideIn;
        animation-delay: .8s;
        margin-top: 40px;
        margin-bottom: 40px;

        @include mq-lg {
          display: none;
        }
      }
    }

    .c-search-location {
      padding-top: 60px;
      padding-bottom: 60px;
      background-color: var(--emu-semantic-colors-contrast-purple-100);

      @include mq(767.98px, 'max-width') {
        opacity: 0;
        &.js-emu-inview {
          animation-delay: 1.1s;
          animation-duration: .8s;
          animation-fill-mode: forwards;
          animation-name: slideIn;
        }
      }

      @include mq-lg {
        margin-top: -40px;
        position: relative;
      }

      &__btn-container {
        gap: 15px;
        row-gap: 20px;

        & > .button {
          margin-bottom: 0;
          margin-right: 0;
        }
      }

      &__btn-container .button .aaaem-button {
        border-color: rgba(71,13,74,0.2);

        &:hover,
        &:focus {
          background-color: rgba(71,13,74,0.2);
          border-color: rgba(71,13,74,0.2);
        }
      }

      .c-search-area {
        background-color: transparent;
        opacity: 0;

        &.js-emu-inview {
          animation-delay: 1.1s;
          animation-duration: .8s;
          animation-fill-mode: forwards;
          animation-name: slideIn;
        }

        .container:first-child {
          margin-bottom: 35px;
        }

        & > .container .aaaem-container {
          background-color: transparent;
        }

        @include mq-lg {
          & > .container {
            padding-left: $avgSpacing * 2;
          }

          & > .container:first-child {
            padding-left: 0;
            margin-bottom: 0;
          }
        }

        @include mq-xlg {
          & > .container {
            max-width: 50%;
            margin-left: 0;
          }
        }
      }

      h2 {
        color: var(--emu-semantic-colors-primary-purple-100);
        font-size: 35px;
        font-weight: 400;
        line-height: 40px;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 20px;
        margin-left: 0;

        @include mq-xxlg {
          font-size: 40px;
        }
      }

      .emu-location-services__location-null-error,
      .emu-location-services__location-input-error {
        position: absolute;
        font-weight: 500;
      }

      .emu-location-services {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        background-color: var(--emu-common-colors-transparent);

        &.js-has-location-null-error {
          .emu-location-services__location-input {
            border: 2px solid var(--emu-semantic-colors-error);
            background-color: #fef8f6;
          }
        }

        &__location-input {
          background-color: var(--emu-common-colors-white);
          border: 1px solid rgba(71,13,74,.2);
          border-bottom-color: var(--emu-semantic-colors-primary-purple-100);
          color: var(--emu-common-colors-black);
          font-size: 16px;
          width: 100%;

          &:focus {
            background-color: #ede5f1;
            border: 2px solid var(--emu-semantic-colors-primary-purple-100);
          }

          &::placeholder {
            color: rgba(0,0,0,.4);
            font-size: 18px;
            font-stretch: normal;
            font-style: normal;
            font-weight: 400;
            letter-spacing: normal
          }
        }

        &__form {
          order: 2;
          margin-bottom: 0;
          width: 100%;

          @include mq-xxlg {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
        }

        &__location-input-wrapper {
          margin: 0;

          @include mq-xxlg {
            width: 66.6666%;
          }
        }

        &__current-location-btn {
          @extend .aaaem-button;
          background-color: var(--emu-semantic-colors-primary-purple-100);
          border: 1px solid var(--emu-semantic-colors-primary-purple-100);
          border-radius: 25px;
          color: var(--emu-common-colors-white);
          position: relative;
          padding-right: 64px;
          margin-bottom: 40px;
          text-overflow: ellipsis;
          overflow: hidden;

          &:before {
            content: "";
            border: 1px solid hsla(0,0%,100%,0.2);
            border-radius: 50px;
            display: inline-block;
            height: 39px;
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            position: absolute;
            right: 5px;
            top: 5px;
            width: 39px;
          }

          &:after {
            content: "";
            background-image: url("data:image/svg+xml;base64,CTxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iTG9jYXRpb24iIHZpZXdCb3g9IjAgMCAxOSAxOSI+CgkJPHRpdGxlPkljb25zL01hcC9Mb2NhdGlvbjwvdGl0bGU+CgkJPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgoJCQk8ZyBpZD0iSWNvbnMvTWFwL0xvY2F0aW9uIiBzdHJva2U9IiNmZmYiPgoJCQkJPHBhdGggZD0iTTE2LjEwMjQ4ODUsMi44OTc1Mjc0NyBMMTAuMzYwNjU2NSwxNy4yNTI2MzgxIEw4LjkyNTAzNzkxLDEwLjA3NTA3NTcgTDEuNzQ3Mzg5Myw4LjYzOTY1MjA1IEwxNi4xMDI0ODg1LDIuODk3NTI3NDcgWiIgaWQ9IlBhdGgiLz4KCQkJPC9nPgoJCTwvZz4KCTwvc3ZnPg==");
            width: 17px;
            display: block;
            height: 17px;
            position: absolute;
            right: 16px;
            top: 16px;
          }

          &:hover,
          &:focus {
            background-color: #38093f;
            border-color: #38093f;
          }
        }

        &__search-submit-cta {
          @extend .aaaem-button;
          visibility: hidden;
          width: 0;
          height: 0;
          overflow: hidden;
          position: absolute;
          top: -9999em;
          left: -9999em;
          background-color: var(--emu-common-colors-white);
          text-transform: capitalize;
          min-width: 22.22%;
          border-color: rgba(71,13,74,0.2);

          &:hover,
          &:focus {
            background-color: rgba(71,13,74,0.2);
            border-color: rgba(71,13,74,0.2);
          }

          @include mq-xxlg {
            position: static;
            top: 0;
            left: 0;
            visibility: visible;
            height: auto;
            width: calc(33.3333% - 15px);
            overflow: visible;
            margin-top: 0;
            margin-left: 15px;
          }
        }
      }
    }

    .juvp1-fac-quotes {
      padding-top: 60px;
      padding-bottom: 120px;
      padding-left: $avgSpacing;
      padding-right: $avgSpacing;
      background-color: var(--emu-common-colors-white);

      @include mq-xxlg {
        padding-top: 240px;
      }

      &__carousel {
        margin-top: 30px;
        max-width: var(--emu-semantic-sizing-content-max-width);
        margin-left: auto;
        margin-right: auto;
        padding-left: $avgSpacing;
        padding-right: $avgSpacing;

        &-footnote {
          margin-top: 20px;
          margin-bottom: 20px;

          @include mq-lg {
            margin-bottom: 0;
          }

          * {
            font-family: var(--emu-semantic-font-families-body);
            font-size: 12px;
            line-height: 1.25;
            margin: 0;
            padding: 0;
            text-align: left;
          }
        }

        &-text {
          margin-top: 20px;
          text-align: center;

          @include mq-lg {
            margin-top: 10px;
            text-align: left;
            padding-left: 30px;
            height: 100%;
            padding-bottom: 100px;
          }

          @include mq-xxlg {
            margin-left: 15%;
            margin-top: 40px;
          }

          p {
            color: var(--emu-common-colors-black);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 15px;
            text-transform: uppercase;

            @include mq-xxlg {
              color: #58585a;
            }
          }

          h3 {
            color: var(--emu-semantic-colors-primary-purple-100);
            font-size: 25px;
            letter-spacing: -.5px;
            line-height: 30px;
            margin-top: 20px;
            margin-right: 0;
            margin-bottom: 20px;
            margin-left: 0;
            position: relative;

            html[dir="rtl"] & {
              text-align: right;
            }

            @include mq-lg {
              margin-top: 10px;

              &:before {
                position: absolute;
                left: -10px;
              }
            }

            @include mq-xxlg {
              font-size: 40px;
              letter-spacing: -1px;
              line-height: 45px;

              &:before {
                left: -15px;
              }
            }

            &:before {
              content: "“";
            }

            &:after {
              content: "”";
            }
          }
        }

        .emu-image {
          padding-top: 75%;
          position: relative;
          width: 100%;

          img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }

      .aaaem-carousel {
        &__actions {
          width: 156px;
          top: auto;
          bottom: -50px;
          left: 50%;
          transform: translateX(-50%);

          @include mq-lg {
            width: 200px;
            top: auto;
            bottom: 40px;
            left: calc(50% + 12px);
            transform: none;
          }

          @include mq-xxlg {
            left: calc(56% + 26px);
          }
        }

        &__item-container {
          .aem-Grid {
            @include mq-lg {
              display: flex;
              flex-wrap: wrap;
            }

            & > .image {
              @include mq-lg {
                flex: 0 0 50%;
              }

              & + .text {
                @include mq-lg {
                  flex: 0 0 50%;
                }
              }
            }
          }
        }
      }
    }

    .juvp1-fac-social {
      background-color: var(--emu-common-colors-white);
      padding-right: 35px;
      padding-left: 35px;
      padding-top: 40px;
      padding-bottom: 40px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      max-width: var(--emu-semantic-sizing-content-max-width);

      @include mq-lg {
        margin-top: 0;
      }

      .aaaem-container {
        background-color: transparent;
      }

      h3 {
        color: var(--emu-semantic-colors-primary-purple-100);
        font-size: 25px;
        letter-spacing: -.5px;
        line-height: 35px;
        margin: 0;

        @include mq-xxlg {
          font-size: 30px;
          line-height: 35px;
        }
      }

      .aaaem-button {
        align-items: center;
        color: var(--emu-semantic-colors-primary-purple-100);
        display: flex;
        font-size: 18px;
        font-family: $graphikFont;
        font-weight: 400;
        justify-content: center;
        text-decoration: none;
        background-color: transparent;
        padding: 0;

        &[data-emu-title="Facebook"] svg {
          fill: #1877f2;
        }

        svg {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }

      &__btns {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        margin-top: 20px;
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;
        max-width: 270px;

        @include mq-lg {
          max-width: 350px;
        }

        @include mq-xxlg {
          margin-top: 32px;
        }
      }

      .juvp1-fac-faq {
        background-color: var(--emu-semantic-colors-secondary-grey-200);
        margin-bottom: 130px;
        margin-left: auto;
        margin-right: auto;
        max-width: 83%;
        padding-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 40px;

        h4 {
          color: var(--emu-semantic-colors-text-light);
          font-size: 25px;
          letter-spacing: -0.5px;
          line-height: 30px;

          @include mq-xxlg {
            font-size: 30px;
            line-height: 35px;
          }
        }

        p {
          color: var(--emu-semantic-colors-contrast-grey-200);
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 20px;
        }

        b {
          color: var(--emu-semantic-colors-text-light);
          line-height: 22.5px;
        }
      }
    }
  }

  // Clinics listing
  &.clinics-page {
    .modal-content__header {
      h1 {
        font-size: 40px;
        font-weight: 400;
        line-height: 45px;
        letter-spacing: -1px;
        margin-bottom: 30px;

        @include mq-xxlg {
          font-size: 50px;
          line-height: 55px;
        }
      }
    }

    .clinics__results {
      font-family: $graphikFont;

      &--number {
        padding-right: 5px;
      }

      &--reset {
        color: var(--emu-semantic-colors-primary-purple-100);
        text-decoration: underline;
      }
    }

    .aaaem-card__title-wrapper {
      height: auto;
      display: flex;

      h2 {
        font-family: var(--emu-semantic-font-families-body);
      }
    }

    .tabpanel__container-clinics-list,
    .tabpanel__container-map {
      background-color: var(--emu-common-colors-white);
    }

    .tabpanel__container-map {
      .options {
        display: flex;
        justify-content: center;
        pointer-events: none;
        width: 100%;
        left: 0;

        .cmp-form-options__field--checkbox + span:before,
        .cmp-form-options__field--checkbox:not(:checked) + span:before {
          border: 1px solid rgba(71,13,74,.2);
        }

        .cmp-form-options__field--checkbox:checked + span:after,
        .cmp-form-options__field--checkbox:not(:checked) + span:after {
          background-color: transparent;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cpath d='M4 10.5 8.5 15 16 6' stroke='%23470d4a' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
        }

        .cmp-form-options--checkbox {
          left: 0;
          pointer-events: all;
          font-family: $graphikFont;
          padding-right: 14px;
        }
      }
    }
  }

  .juvederm-ageverification {
    .modal-content {
      margin-top: 15%;
    }

    .cmp-title__text,
    .cmp-text p {
      color: var(--emu-semantic-colors-text-light);
    }

    .cmp-title__text {
      font-size: 35px;
    }

    .cmp-text p {
      text-align: center;
    }

    .modal-cta-container {
      .button:nth-child(2) {
        .aaaem-button {
          background-color: var(--emu-semantic-colors-primary-purple-100);
          color: var(--emu-common-colors-white);

          &:hover,
          &:focus {
            background-color: var(--emu-semantic-colors-secondary-purple-200);
            border-color: var(--emu-semantic-colors-secondary-purple-200);
          }
        }
      }
    }
  }
}

// Clinic detail page
.juvederm-phase1__clinic {
  header.header {
    position: fixed;
    animation: none;

    .juvederm-navigation-findaclinic {
      display: none;
    }

    .header__logo {
      width: auto;
      text-align: left;

      @include mq-lg {
        width: 100%;
        text-align: center;
      }
    }
  }

  .languagenavigation {
    display: none;
  }

  .juvederm-clinic-details-page {
    .aaaem-container {
      background-color: var(--emu-common-colors-white);

      @include mq-lg {
        display: grid;
        margin-bottom: 90px;
        grid-template-columns: 50% 50%;
      }

      .icldetail {
        @include mq-lg {
          grid-column: 1 / 3;
          grid-row: 1;
        }

        & + .text {
          z-index: 1;

          @include mq-lg {
            grid-column: 2;
            width: 100%;
            max-width: none;
            position: static;
            grid-row: 1;
            margin-top: 160px;
            margin-left: auto;
            margin-bottom: auto;
            padding-right: $avgSpacing;
            padding-left: $avgSpacing;
          }

          .js-clinic-has-image & {
            @include mq-lg {
              margin-top: 520px;
            }

            @include mq-xlg {
              margin-top: 614px;
            }

            @include mq-content {
              margin-top: 636px;
            }
          }

          @include mq-xxlg {
            margin-top: 224px;

            .js-clinic-has-image & {
              margin-top: 700px;
            }
          }
        }

        &__modal {
          .aaaem-container {
            display: block;
            grid-template-columns: unset;
            margin-bottom: 0;
          }

          .modal__cta--confirm {
            background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
            border-radius: 25px;
            color: var(--emu-common-colors-white);
            display: inline-block;
          }
        }
      }
    }

    .icldetail + .text .aaaem-text {
      @include mq-lg {
        position: relative;
        max-width: 540px;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        background-color: #f4f4f4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      @include mq-xxlg {
        max-width: 445px;
        padding: 40px;
      }

      h4 {
        font-size: 25px;
        line-height: 1.2;

        @include mq-xxlg {
          font-size: 30px;
          line-height: 1.17;
        }
      }

      p {
        font-family: $graphikFont;
        line-height: 30px;
        font-size: 18px;
        color: var(--emu-semantic-colors-contrast-grey-200);
      }

      b {
        font-family: $graphikFont;
        line-height: 22px;
        line-height: 30px;
        font-weight: 700;
        color: var(--emu-semantic-colors-primary-purple-100);

        &:before {
          background-color: var(--emu-semantic-colors-contrast-purple-100);
        }
      }
    }
  }

  .icl-detail__header {
    position: static;
    margin-top: 60px;
    background-color: var(--emu-semantic-colors-contrast-purple-100);
    margin-bottom: 0;
    padding-bottom: 24px;

    &:before {
      display: none;

      @include mq(1100px) {
        display: block;
        background-color: var(--emu-semantic-colors-contrast-purple-100);
        top: 0;
      }
    }

    @include mq-lg {
      padding-top: 30px;
      padding-bottom: 32px;
      margin-top: 30px;
      position: relative;
    }

    @include mq-xxlg {
      margin-top: 90px;
    }

    .icl-detail__title {
      font-family: var(--emu-semantic-font-families-body);
      font-size: 25px;
      line-height: 30px;
      font-weight: 500;
      letter-spacing: 0;
      margin-bottom: 6px;
      margin-top: 0;
      padding-left: $avgSpacing;
      padding-right: $avgSpacing;
      padding-top: 17px;

      @include mq-lg {
        padding-top: 0;
        font-size: 40px;
        line-height: 45px;
        letter-spacing: -1px;
        margin-bottom: 20px;
        max-width: none;
        width: 50%;
      }

      @include mq-xxlg {
        width: 384px;
        margin-left: 8%;
      }
    }

    .icl-detail__text {
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      margin: 0;
      padding-left: $avgSpacing;
      padding-right: $avgSpacing;
      position: relative;

      @include mq-lg {
        max-width: none;
        width: 50%;
      }

      @include mq-xxlg {
        width: 384px;
        margin-left: 8%;
      }
    }

    // back to results link
    .icl-detail__link {
      position: fixed;
      right: 20px;
      max-width: calc(100% - 16px);
      font-weight: 700;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0;
      color: var(--emu-common-colors-white);
      text-decoration: underline;
      padding: 0;
      top: 18px;
      z-index: var(--emu-common-other-z-index-header);

      @include mq-lg {
        max-width: calc(100% - 135px);
        font-size: 18px;
        line-height: 30px;
        top: 12px;
      }

      @include mq-content {
        margin-left: 940px;
        left: auto;
        right: auto;
      }

      @include mq-xxlg {
        top: 28px;
      }
    }

    .icl-detail__image {
      width: 100%;
      margin: 0;

      @include mq-lg {
        left: calc(50% - -15px);
        max-height: 322px;
      }

      @include mq-xlg {
        max-height: 424px;
      }

      @include mq-content {
        max-height: 445px;
      }
    }
  }

  .icl-detail__container {
    padding-right: 0;
    padding-left: 0;
  }

  .icl-detail__body {
    padding-right: $avgSpacing;
    padding-left: $avgSpacing;

    @include mq-lg {
      width: 50%;
    }

    @include mq-xxlg {
      width: 384px;
      margin-left: 8%;
    }

    .icl-detail__button {
      text-transform: capitalize;
      padding-top: 8px;
      padding-right: 64px;
      padding-bottom: 12px;
      padding-left: 20px;
      height: 50px;
      border-color: rgba(71,13,74,0.2);
      transition: border-color .3s, background-color .3s;

      &:before {
        display: none;
      }

      &:hover,
      &:focus {
        background-color: rgba(71,13,74,0.2);
        border-color: rgba(71,13,74,0.2);
      }

      &.icl-detail__button--website {
        &:hover,
        &:focus {
          background-color: #38093f;
          border-color: #38093f;
        }
      }
    }

    .icl-detail__info {
      margin-top: 40px;
      padding-top: 0;
      padding-bottom: 40px;
      margin-bottom: 0;
      border-bottom: 1px solid var(--emu-semantic-colors-contrast-grey-100);
      border-top: 0;

      &:first-of-type {
        margin-top: 21px;

        @include mq-lg {
          margin-top: 40px;
        }

        @include mq-xxlg {
          margin-top: 70px;
        }
      }

      &.icl-detail__info--hours {
        .icl-detail__label {
          margin-bottom: 40px;
        }

        .icl-detail__text {
          &:after {
            content: ",";
          }

          &:last-of-type:after {
            display: none;
          }
        }
      }

      &.icl-detail__info--location {
        display: grid;
        border: 0;

        .icl-detail__label,
        .icl-detail__link {
          grid-row: 1;
          margin: 0;
        }

        .icl-detail__text {
          grid-row: 2;
          grid-column: 1 / 3;
          margin-top: 10px;
        }

        .icl-detail__image {
          grid-row: 3;
          grid-column: 1 / 3;
        }
      }
    }

    .icl-detail__text {
      color: var(--emu-semantic-colors-contrast-grey-200);
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0;
      font-weight: 400;
    }

    .icl-detail__label {
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 10px;
      color: var(--emu-semantic-colors-primary-purple-100);
    }
  }

  .icl-detail__footer {
    border-top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    @include mq-xxlg {
      padding-right: 96px;
      padding-left: 96px;
    }

    &:before {
      background-color: var(--emu-common-colors-white);
      border-top: 1px solid var(--emu-semantic-colors-transparent-black-200);
    }

    .icl-detail__button {
      &:before {
        display: none;
      }

      &--phone {
        border-color: rgba(71,13,74,0.2);

        @include mq(767.98px, 'max-width') {
          font-size: 0;
          padding-top: 0;
          padding-right: 25px;
          padding-bottom: 0;
          padding-left: 25px;

          .icl-detail__icon {
            border: 0;
          }
        }

        @include mq-lg {
          &:hover,
          &:focus {
            background-color: rgba(71,13,74,0.2);
            border-color: rgba(71,13,74,0.2);
          }
        }
      }
    }
  }

  .icl-detail__base {
    @include mq-lg {
      margin-top: 90px;
    }
  }

  footer.experiencefragment {
    .upper_section .aem-Grid {
      // top section, MLR
      & > .text:first-child {
        display: none;
      }
    }
  }
}

// authoring
.cq-Editable-dom--container {
  .juvp1-image__desktop,
  .juvp1-image__mobile {
    display: block !important;
    position: relative;

    &:before {
      background-color: #CCC;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      opacity: .5;
      color: #000;
    }
  }

  .juvp1-treatment-areas__items > .text {
    opacity: 1 !important;
    grid-area: auto !important;
    visibility: visible !important;
    position: relative;

    &:before {
      background-color: #CCC;
      width: 100%;
      top: 0;
      left: 0;
      font-size: 12px;
      opacity: .5;
      color: #000;
    }

    &:nth-child(1):before {
      content: "SECTION: CHEEKS / JAW";
    }

    &:nth-child(2):before {
      content: "SECTION: EYE AREA";
    }

    &:nth-child(3):before {
      content: "SECTION: LIPS";
    }
  }

  .juvp1-image__desktop {
    &:before {
      content: "DESKTOP IMAGE";
      background-color: #CCC;
    }
  }

  .juvp1-image__mobile {
    &:before {
      content: "MOBILE IMAGE";
      background-color: #CCC;
    }
  }

  .modal-wrapper,
  .modal-content {
    display: block !important;
    opacity: 1 !important;
    transform: none !important;
    position: static !important;
    width: 100% !important;
  }
}

// Country overrides
html[lang="th-TH"],
html[lang="no-NO"],
html[lang="pl-PL"] {
  #juvederm-phase1 {
    // Show MLR codes on all pages.
    &.juvederm-phase1__secondary,
    &.juvederm-phase1__fac,
    &.clinics-page {
      footer.experiencefragment {
        .upper_section .aem-Grid {
          // top section, MLR
          & > .text:first-child {
            display: inherit;
          }
        }
      }
    }
  }
}
html[lang="he"],
html[lang="he_il"]{
  .aaaem-button {
    font: var(--emu-component-actions-button-text-typography-assistant-narrow);
    @include mq($emu-semantic-sizing-breakpoints-large) {
    font: var(--emu-component-actions-button-text-typography-assistant-wide);
    }
  }
  #juvederm-phase1,
  .juvederm-phase1__clinic {
    // Clinics listing
    &.clinics-page {
      .aaaem-card__title-wrapper {
        h2 {
          font-family: var(--emu-semantic-font-families-body-assistant);
        }
      }
    }

  }
}
