.cmp-teaser {
  &.has-media {
    height: calc(100vh - 50px);

    @include aem-editor-view() {
      height: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-large) {
      height: 100vh;

      @include aem-editor-view() {
        height: auto;
      }
    }

    &:not(.has-video) {
      height: 50vh;

      @include aem-editor-view() {
        height: auto;
      }

      @include mq($emu-semantic-sizing-breakpoints-medium) {
        height: 75vh;

        @include aem-editor-view() {
          height: auto;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        height: 100vh;

        @include aem-editor-view() {
          height: auto;
        }
      }
    }
  }

  &.has-video {
    &::after {
      animation: lineGradient 2s ease infinite;
      background: linear-gradient(45deg, var(--emu-common-colors-white), var(--emu-semantic-colors-primary-purple-100));
      background-size: 400% 400%;
      bottom: -40px;
      content: "";
      display: block;
      height: 80px;
      left: 50%;
      position: absolute;
      width: 1px;
      z-index: var(--emu-common-other-z-index-layer);
    }
  }

  &:not(.has-video) {
    &::after {
      background-color: var(--emu-semantic-colors-transparent-black-300);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__description {
    padding-left: 15px;
    padding-right: 15px;
  }

  .content-container {
    max-width: var(--emu-semantic-sizing-content-max-width);

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-bottom: var(--emu-component-cards-teaser-padding-bottom-wide);
      padding-left: var(--emu-component-cards-teaser-padding-left-wide);
      padding-right: var(--emu-component-cards-teaser-padding-right-wide);
      padding-top: var(--emu-component-cards-teaser-padding-top-wide);
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .content {
    z-index: var(--emu-common-other-z-index-layer);

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      max-width: 83%;
    }
  }

  .teaser-video-controls {
    display: none;
  }
}

@keyframes lineGradient {
  0% {
    background-position: 50% 0;
  }

  50% {
    background-position: 50% 100%;
  }

  to {
    background-position: 50% 0;
  }
}
