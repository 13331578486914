.juvederm-keytakeaways {
  background-color: var(--emu-semantic-colors-transparent-purple-200);
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    padding-bottom: 50px;
    padding-top: 60px;
  }

  &__container {
    background-color: var(--emu-common-colors-transparent);
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);

    @include mq($emu-semantic-sizing-breakpoints-large) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    > .title {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex: 0 0 100%;
      }
    }

    > .text {
      position: relative;

      &:not(:last-of-type) {
        margin-bottom: 30px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-bottom: 0;
        }
      }

      &::after {
        background-color: var(--emu-semantic-colors-primary-purple-100);
        bottom: 0;
        width: 30px;
        content: '';
        display: block;
        height: 2px;
        left: calc(8% + 15px);
        position: absolute;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          left: 15px;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex: 0 0 33%;
      }
    }
  }

  .cmp-title,
  .cmp-text {
    margin-left: 8%;
    padding-left: 15px;
    padding-right: 15px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-left: 0;
    }
  }

  .cmp-title {
    margin-bottom: 40px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-bottom: 60px;
    }

    h3.cmp-title__text {
      font-family: var(--emu-semantic-font-families-body);
      font-size: 41px;
      font-style: italic;
      font-weight: 300;
      line-height: 45px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        font-size: 61px;
        letter-spacing: -1px;
        line-height: 70px;
      }
    }
  }

  .cmp-text {
    padding-bottom: 10px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      padding-bottom: 36px;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-right: 8%;
      padding-bottom: 10px;
    }

    p {
      font-size: 18px;
      font-style: italic;
      font-weight: 700;
      line-height: 25px;
    }

    sup {
      font-weight: 500;
    }
  }
}
// Country overrides
html[lang='he'] {
  .juvederm-keytakeaways {
    .cmp-title {
      h3.cmp-title__text {
        font-family: var(--emu-semantic-font-families-body-assistant);
      }
    }
  }
}
