.juvederm-contact-us-page {
  margin-top: 120px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-top: 180px;
  }

  > .title,
  > .text {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
  }

  > .title {
    margin-bottom: 20px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 50px;
    }
  }

  > .text {
    margin-bottom: 60px;
    padding-left: 15px;
    padding-right: 15px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 90px;
    }

    .aaaem-text {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-left: 8%;
        max-width: 83%;
      }
    }

    p {
      color: var(--emu-common-colors-black);
      font-size: 18px;
      line-height: 22px;
    }

    img {
      display: inline-block;
    }
  }
}