.juvederm-ageverification {
  .main-close {
    display: none;
  }

  .modal-wrapper.is-open {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    margin-bottom: unset;
    margin-top: unset;
    max-width: 550px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }

  .aaaem-container {
    background-color: var(--emu-common-colors-white);
  }

  .emu-title__text {
    font-size: 35px;
    letter-spacing: -1px;
    line-height: 40px;
    text-align: center;
  }

  .aaaem-text {
    margin-bottom: 20px;
    margin-top: 20px;

    p {
      font-size: 18px;
      line-height: 30px;
    }
  }

  .modal-cta-container {
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      flex-direction: row;
      gap: 20px;
      justify-content: space-between;
    }

    .button {
      flex: 1 0 auto;
      max-width: 100%;

      .aaaem-button {
        text-align: center;
        width: 100%;

        span {
          width: 100%;
        }
      }

      &:first-child {
        .aaaem-button {
          border-color: var(--emu-semantic-colors-transparent-magenta-200);
          color: var(--emu-semantic-colors-text-light);
          height: 50px;
          padding-bottom: 12px;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 8px;

          &:hover {
            background-color: var(--emu-semantic-colors-transparent-purple-light);
            border-color: var(--emu-semantic-colors-transparent-purple-light);
          }
        }
      }

      &:nth-child(2) {
        .aaaem-button {
          height: auto;
          min-height: 50px;
        }
      }
    }
  }
}