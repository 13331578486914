.juvederm-productsshowcase {
  background-color: var(--emu-semantic-colors-transparent-magenta-100);
  padding-top: 50px;
  padding-bottom: 60px;
  padding-right: 15px;
  padding-left: 15px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-right: 0;
    padding-left: 0;
  }

  .title {
    max-width: var(--emu-semantic-sizing-content-max-width);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 40px;
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 27px;
        font-weight: 500;
        line-height: 35px;
      }
    }
  }

  .carousel {
    max-width: var(--emu-semantic-sizing-content-max-width);
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .carouselslide {
      background-color: var(--emu-common-colors-white);
      min-width: 295px;
      border-radius: 12px;
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      height: 100%;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        min-width: 255px;
      }

      .aaaem-card__background-image {
        margin-bottom: 15px;
      }

      .aaaem-card__wrapper {
        background-color: var(--emu-common-colors-transparent);
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        h4 {
          font-size: 27px;
          font-weight: 500;
          line-height: 35px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 25px;
          margin-top: 20px;
          margin-bottom: 20px;

          @include mq($emu-semantic-sizing-breakpoints-xx-large) {
            font-size: 18px;
          }
        }

        .aaaem-card__content-wrapper {
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .c-products-showcase__desc {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .aaaem-carousel {
    &__content {
      display: flex;
    }

    &__actions {
      display: none;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        display: block;
        position: absolute;
        top: -81px;
        right: 10px;
        margin-bottom: 40px;
      }
    }

    &__action {
      margin-left: 20px;
      background-color: var(--emu-common-colors-transparent);
      border: 1px solid var(--emu-semantic-colors-primary-purple-100);
      border-radius: 50px;
      cursor: pointer;
      display: inline-block;
      width: 50px;
      height: 50px;
      overflow: hidden;
      position: relative;
      text-decoration: none;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
        pointer-events: none;
      }

      svg {
        display: none;
      }

      &-prev,
      &-next {
        &::before {
          background-repeat: no-repeat;
          content: '';
          display: inline-block;
          height: 100%;
          position: inherit;
          right: -6px;
          top: 7px;
          width: 100%;
        }

        &::after {
          background: linear-gradient(
            90deg,
            var(--emu-semantic-colors-transparent-purple-100),
            var(--emu-common-colors-transparent)
          );
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          transform: translateX(-100%);
          transition: 0.3s;
          width: 100%;
        }

        &:hover {
          &::after {
            transform: translateX(0);
          }
        }
      }

      &-prev::before {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='19px' height='19px' viewBox='0 0 19 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons/Arrows/Left Arrow%3C/title%3E%3Cg id='Symbols' stroke='%23470d4a' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-3' stroke='%23470d4a' transform='translate(9.500000, 9.500000) scale(-1, 1) translate(-9.500000, -9.500000) '%3E%3Cg id='Group-2' transform='translate(3.000000, 4.000000)'%3E%3Cline x1='0' y1='5.5' x2='12.5' y2='5.5' id='Path-2'%3E%3C/line%3E%3Cpolyline id='Path-3' points='7 0 12.5 5.5 7 11'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }

      &-next::before {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='19px' height='19px' viewBox='0 0 19 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons/Arrows/Right Arrow%3C/title%3E%3Cg id='Symbols' stroke='%23470d4a' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icons/Arrows/Right-Arrow' stroke='%23470d4a'%3E%3Cline x1='3' y1='9.5' x2='15.5' y2='9.5' id='Path-2'%3E%3C/line%3E%3Cpolyline id='Path-3' points='10 4 15.5 9.5 10 15'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  .tns-ovh {
    height: auto !important; /* overwrite the height set by Tiny Slider */
  }
}
