// returns pre-defined breakpoint from map, or else custom value passed in
@function breakpoint($breakpoint) {
  @if (map-has-key(breakpoints(), $breakpoint)) {
    @return map-get(breakpoints(), $breakpoint);
  } @else {
    @return $breakpoint;
  }
}

@mixin mq($breakpoint, $respond: "min-width") {
  @media (#{$respond}: breakpoint($breakpoint)) {
    @content;
  }
}

@mixin mq-sm() {
  @media (min-width: $emu-semantic-sizing-breakpoints-small) {
    @content;
  }
}

@mixin mq-lg() {
  @media (min-width: $emu-semantic-sizing-breakpoints-large) {
    @content;
  }
}

@mixin mq-xlg() {
  @media (min-width: $emu-semantic-sizing-breakpoints-x-large) {
    @content;
  }
}

@mixin mq-xxlg() {
  @media (min-width: $emu-semantic-sizing-breakpoints-xx-large) {
    @content;
  }
}

@mixin mq-content() {
  @media (min-width: $emu-semantic-sizing-content-max-width) {
    @content;
  }
}