.juvederm-navigation {
  &-under18--mobile {
    display: block;
    padding: var(--emu-common-spacing-none);

    .juvederm-navigation-wrapper-items {
      margin-top: 10px;
      margin-bottom: 10px;
      background-color: var(--emu-common-colors-transparent);
      padding: var(--emu-common-spacing-none);

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: auto;
      }
    }

    .emu-navigation {
      &__content-wrapper {
        nav {
          p,
          a {
            color: var(--emu-common-colors-white);
            cursor: pointer;

            &:hover,
            &:active {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
