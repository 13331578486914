.c-consultation-wizard {
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  padding-left: 15px;
  padding-right: 15px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-top: 150px;
  }

  .cmp-title__text {
    margin-bottom: 30px;
  }

  .cmp-text {
    margin-bottom: 30px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-bottom: 60px;
    }

    p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cmp-button {
    &.c-consultation-wizard__button {
      &-female--active,
      &-male--active {
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-semantic-colors-primary-purple-100);

        &:active {
          outline: none;
        }
      }
    }

    &.c-consultation-wizard__modal-trigger {
      display: none;
    
      @include aem-editor-view() {
        display: block !important;
      }
    }
  }

  .progressbar {
    &__base {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-bottom: 60px;
      position: relative;
      width: 100%;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        gap: 60px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        gap: 120px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        gap: 180px;
      }
    }

    &__step {
      align-items: center;
      border: 4px solid var(--emu-semantic-colors-primary-purple-100);
      border-radius: 50%;
      color: var(--emu-component-ingredients-title-color-light);
      display: flex;
      flex-shrink: 0;
      font-size: 16px;
      font-weight: bold;
      height: 40px;
      justify-content: center;
      line-height: 30px;
      position: relative;
      width: 40px;
      z-index: 2;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        height: 50px;
        width: 50px;
      }

      &::after {
        background-color: var(--emu-semantic-colors-primary-purple-100);
        content: '';
        height: 4px;
        left: calc(100% + 4px); // + 4px to adjust for the border
        position: absolute;
        right: calc(-20px - 4px); // -20px is the grid gap and + 4px to adjust for the border
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          right: calc(-60px - 4px);
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          right: calc(-120px - 4px);
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          right: calc(-180px - 4px);
        }
      }

      &:last-child::after {
        display: none;
      }

      &--active {
        background-color: var(--emu-semantic-colors-primary-purple-100);
        color: var(--emu-common-colors-white);
      }

      &-number {
        position: relative;
        z-index: 3;
      }
    }
  }

  &__step {
    display: none;

    @include aem-editor-view() {
      display: block;
    }

    &--active {
      display: block;
    }

    &-one {
      .image {
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 50%;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          max-width: 70%;
        }
      }

      .button {
        margin-bottom: 40px;
        text-align: center;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-bottom: 0;
        }
      }
    }

    .cmp-button.js-toggle-on {
      background-color: var(--emu-common-colors-transparent);
      color: var(--emu-semantic-colors-primary-purple-100);

      &:active {
        outline: none;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-around;
    margin-top: 70px;

    @include aem-editor-view() {
      display: block;
    }

    .button:nth-child(2) {
      margin-left: auto;
    }
  }

  &__button {
    &-prev,
    &-next,
    &-download {
      @include aem-editor-view() {
        display: block !important;
      }
    }

    &-next,
    &-download {
      margin-left: auto;

      @include aem-editor-view() {
        margin-left: 0;
      }
    }

    &-download {
      margin-left: 10px;
    }

    &-area {
      .cmp-button__icon-text {
        display: none;
      }
    }
  }

  &__accordion-content-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    @include aem-editor-view() {
      display: block;
    }

    .button {
      margin-bottom: 15px;
      margin-right: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .aaaem-accordion {
    margin-left: 0;
    margin-right: 0;

    &__header {
      border-bottom: 0;
      border-top: 1px solid var(--emu-semantic-colors-contrast-grey-100);
      padding-bottom: 14px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 5px;

      &.-ba-is-active {
        .aaaem-accordion__icon {
          &::before {
            background-color: var(--emu-common-colors-none);
            transform: rotate(135deg);
          }
        }
      }
    }

    &__title {
      color: var(--emu-semantic-colors-text-light);
      font-size: 16px;
      font-weight: bold;
      line-height: 30px;
    }

    &__icon {
      display: block;
      height: 13px;
      position: relative;
      width: 13px;

      &::before {
        background-color: var(--emu-common-colors-none);
        border-bottom: 2px solid var(--emu-semantic-colors-primary-purple-100);
        border-left: 2px solid var(--emu-semantic-colors-primary-purple-100);
        border-radius: 0;
        content: "";
        height: 13px;
        left: unset;
        position: absolute;
        right: 8px;
        top: 2px;
        transform: translateY(0) rotate(-45deg);
        transition: all 0.3s;
        width: 13px;
        will-change: transform;
      }

      &::after {
        display: none;
      }
    }

    &__panel {
      &.-ba-is-active {
        max-height: none !important; // To overwrite the 0 value set by the JS of the accordion
      }
    }

    .js-badger-accordion-panel-inner {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }
  }

  .modal-content {
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      width: 60%;
    }

    .cmp-container {
      background-color: var(--emu-semantic-colors-none);
    }

    .button[data-ref="confirm-cta"] {
      display: none;

      @include aem-editor-view() {
        display: block;
      }
    }
  }

  &__container {
    &-genders {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        display: flex;
      }

      @include aem-editor-view() {
        display: block;
      }
    }

    &-happy,
    &-neutral,
    &-angry {
      aspect-ratio: 1/1;
      background-color: var(--emu-semantic-colors-contrast-purple-600);
      display: flex;

      @include aem-editor-view() {
        aspect-ratio: unset;
        display: block;
      }
    }

    &-happy,
    &-neutral {
      margin-bottom: 30px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-bottom: 0;
      }
    }

    &-emotions {
      display: flex;
      flex-direction: column;

      @include aem-editor-view() {
        display: block;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        flex-direction: row;
        gap: 20px;
      }

      > .container {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          flex: 1;
        }
      }
    }

    &-selections {
      &-step2,
      &-step3 {
        margin-bottom: 30px;
    
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-bottom: 60px;
        }
      }
    }
  }

  &__form {
    height: 100%;
    width: 100%;
  }

  &__text {
    &-happy,
    &-neutral,
    &-angry {
      display: none;

      @include aem-editor-view() {
        display: block;
      }
    }
  }

  &__label {
    align-items: center;
    background-position: center;
    background-size: cover;
    color: var(--emu-semantic-colors-text-light);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 100%;
    justify-content: center;
    line-height: 30px;
    padding-left: 60px;
    padding-right: 60px;
    text-align: center;
    width: 100%;
  }

  &__input {
    display: none;
  }

  &__title {
    &-selection {      
      .cmp-title__text {
        border-bottom: 1px solid var(--emu-semantic-colors-contrast-grey-100);
      }
    }
  }

  &__selection {
    color: var(--emu-semantic-colors-text-light);
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
    margin-right: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
