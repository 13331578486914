.c-find-the-clinic-video {
  padding-top: 120px;
  padding-bottom: 120px;

  &.js-emu-inview {
    .c-find-the-clinic-video__content {
      animation-name: slideIn;
    }
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  &__content {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity: 0;
    width: 100%;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    background-color: var(--emu-semantic-colors-primary-purple-100);
    border-radius: 10px;
    -webkit-font-smoothing: antialiased;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 30px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      max-width: 540px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      max-width: 730px;
      padding-top: 30px;
      padding-right: 35px;
      padding-bottom: 30px;
      padding-left: 35px;
    }
  }

  &__heading .emu-title__text,
  &__heading h2 {
    font-family: var(--emu-semantic-font-families-heading);
    font-size: 41px;
    font-weight: 400;
    color: var(--emu-common-colors-white);
    text-align: center;
    letter-spacing: normal;
    line-height: 1.1;
    margin-bottom: 40px;
    margin-top: 0;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 30px;
      font-size: 61px;
      line-height: 1.15;
    }
  }

  &__search {
    .emu-location-services__search-submit-cta {
      display: none;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        display: block;
      }
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .c-find-the-clinic-video {
    &__heading .emu-title__text,
    &__heading h2 {
      font-family: var(--emu-semantic-font-families-heading-assistant);
    }
  }
}