.juvederm-findaclinic__container {
  background-color: var(--emu-semantic-colors-primary-purple-100);
  margin-bottom: -30px;

  &::after {
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEyIiBoZWlnaHQ9IjQ5OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggZD0iTTU0LjQ3IDE3My4wODVjNzAtNDYuNDgyIDEyMS4xMjcgMi45MjIgMTEyLjg4IDc2Ljg0OEMxNTEuNTc0IDM5Mi4yNTEgMjI2LjMzMyA1MTUuNTUgMzQ1IDQ3Ni44NTUgMTA5LjU3NCA1ODIuMDQ2LTg0LjIxMSAyNjUuMzEzIDU0LjQ3IDE3My4wODV6TTQwMS4xODcgNDRjMjA5LjA2NyAxNTIuMTUyIDMyLjQyOCA0NzguNjU5LTExNi43OTYgNDA0LjA4NS03NS4zODgtMzcuNjE0LTU4LjMyNS0xMDYuNjcgOS43MzEtMTM2LjQ2NEM0MjUuMDc1IDI1NC4zNTIgNDk0LjIxIDEyNy44OTYgNDAxLjE4NyA0NHpNMCAyMTJDMjYuNjU2LTQ1LjQwNCAzOTYuMzMzLTU1LjIzMSA0MDYuNzIyIDExMS41NjJjNS4xNzggODQuMTY0LTYyLjkwMiAxMDMuODgyLTEyMi41MTUgNTkuNzQ0QzE2OS4zMjkgODYuMjc2IDI1Ljc2IDg5LjM3OCAwIDIxMnoiIGlkPSJhIi8+PC9kZWZzPjx1c2UgZmlsbD0iI0ZGRiIgeGxpbms6aHJlZj0iI2EiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04NSkiLz48L3N2Zz4=");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90% auto;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    pointer-events: none;
    top: 35;
    left: -28px;

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      background-position: bottom -5rem;
      top: 0;
      left: 0;
    }

    @include mq($emu-semantic-sizing-breakpoints-large) {
      background-position: left 15.5rem;
      background-size: 33vw auto;
    }
  }

  .juvederm-findaclinic-hero{
    background-color: var(--emu-common-colors-transparent);
    padding-bottom: 0;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    max-width: 100%;
  
    @include mq($emu-semantic-sizing-breakpoints-large) {
      flex-direction: row;
      max-width: var(--emu-semantic-sizing-content-max-width);
      padding-top: 53px;
    }
  
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-top: 80px;
    }
  
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-left: auto;
      margin-right: auto;
      padding-top: 90px;
    }
  
    >div:nth-child(1) {
      width: 100%;
  
      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  
    >div:nth-child(2) {
      width: 100%;
  
      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 50%;
      }
    }
  
    .findaclinic-hero-text {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
      animation-name: fadeIn;
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
  
      .title {
        h1 {
          color: var(--emu-common-colors-white);
          max-width: 290px;
          margin-top: 52px;
          margin-bottom: 38px;
          font-size: 41px;
          letter-spacing: -1px;
          line-height: 45px;
  
          @include mq($emu-semantic-sizing-breakpoints-large) {
            margin-bottom: 30px;
          }
  
          @include mq($emu-semantic-sizing-breakpoints-xx-large) {
            max-width: 450px;
            margin-bottom: 30px;
            font-size: 61px;
            line-height: 70px;
          }
        }
      }
  
      .locationservices {
        margin-bottom: 60px;
  
        button {
          background-color: var(--emu-common-colors-white);
          font-weight: 400;
          padding-right: 3.2rem;
          border: 1px solid var(--btn-border);
          border-radius: 2.5rem;
          color: var(--btn-text);
          cursor: pointer;
          display: inline-block;
          font-family: var(--emu-semantic-font-families-body);
          font-size: 18px;
          line-height: 30px;
          width: fit-content;
          overflow: hidden;
          padding-top: 8px;
          padding-right: 28px;
          padding-bottom: 8px;
          padding-left: 28px;
          position: relative;
          text-decoration: none;
          text-overflow: ellipsis;
          text-transform: uppercase;
          transition: 0.3s;
          white-space: nowrap;
        }
      }
    }
  
    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
    }
  
    .image {
      width: 100%;
      animation-name: revealFromBottom;
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      display: flex;
      justify-content: flex-end;
  
      img {
        display: block;
        object-fit: cover;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          width: 370px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          width: 450px;
          padding-right: 15px;
        }
      }
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .juvederm-findaclinic__container {
    .juvederm-findaclinic-hero{
      .findaclinic-hero-text {
        .locationservices {    
          button {
            font-family: var(--emu-semantic-font-families-body-assistant);
          }
        }
      }
    }
  }

}