.aaaem-button {
  align-items: center;

  &.aaaem-button__primary-outline {
    height: 40px;
    transition: .3s;

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      padding-bottom: var(--emu-component-actions-button-padding-bottom-narrow);
      padding-left: var(--emu-component-actions-button-padding-left-narrow);
      padding-right: var(--emu-component-actions-button-padding-right-narrow);
      padding-top: var(--emu-component-actions-button-padding-top-narrow);
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-bottom: var(--emu-component-actions-button-padding-bottom-wide);
      padding-left: var(--emu-component-actions-button-padding-left-wide);
      padding-right: var(--emu-component-actions-button-padding-right-wide);
      padding-top: var(--emu-component-actions-button-padding-top-wide);
    }
  }

  &.aaaem-button__primary-filled {
    line-height: 30px;
    padding: 9px 32px;

    &:visited {
      color: var(--emu-common-colors-white);
    }
  }

  &.aaaem-button__secondary-filled {
    animation: flashlight 8s infinite;
    background-position: 125%;
    background-size: 150% 100%;
    background-image: radial-gradient(circle,rgba(148,54,141,.75) 15%, var(--emu-common-colors-transparent) 50%);
    line-height: 32px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    transition: background-color 0.5s 0.1s;

    &:hover,
    &:focus {
      > span > span {
        animation: slide-top 0.5s 0.1s;
      }

      svg path {
        animation: slide-right 0.5s 0.1s;
      }
    }

    > span {
      display: inline-block;
      overflow: hidden;

      > span {
        display: inline-block;
      }
    }

    svg {
      height: 18px;
      margin-left: 0;
      margin-right: 15px;
      overflow: hidden;
      width: 18px;
    }

    &:disabled {
      animation: none;
      background-color: var(--emu-semantic-colors-contrast-grey-100);
      background-image: none;
      cursor: not-allowed;
      transition: none;

      &:active,
      &:focus,
      &:hover {
        background-color: var(--emu-semantic-colors-contrast-grey-100);
        background-image: none;
        outline: none;
      }

      > span > span {
        animation: none;
      }

      svg path {
        animation: none;
      }
    }

    &.add-icon-right {
      padding-left: 32px;

      svg {
        margin-left: 15px;
        margin-right: 0;

      }
    }
  }
}

@keyframes flashlight {
  0% {
    background-position: 125%;
  }

  50% {
    background-position: -25%;
  }

  to {
    background-position: 125%;
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0)
  }

  50% {
    transform: translateX(150%)
  }

  51% {
    transform: translateX(-100%)
  }

  to {
    transform: translateX(0)
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-100%)
  }

  51% {
    transform: translateY(100%)
  }

  to {
    transform: translateY(0)
  }
}