.juvederm-bulletpoints {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 40px;
  margin-left: 0;

  .tns-outer {
    display: flex;
    justify-content: center;
  }

  .tns-inner {
    max-width: var(--emu-semantic-sizing-content-max-width);

    .aaaem-carousel__content {
      display: flex;
      gap: 6rem 3rem;
    }

    .cmp-text {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 20px;
      font-size: 12px;

      h4 {
        padding-bottom: 30px;
        font-style: italic;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        font-family: var(--emu-semantic-font-families-body);
      }

      p {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}
// Country overrides
html[lang='he'],
html[lang='he_il'] {
  .juvederm-bulletpoints {
    .tns-inner {
      .cmp-text {
        h4 {
          font-family: var(--emu-semantic-font-families-body-assistant);
        }
      }
    }
  }
}
