.juvederm-qacontainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  margin-top: 45px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-bottom: 60px;
  }

  .title {
    width: var(--emu-semantic-sizing-content-max-width);

    h2 {
      margin-bottom: 40px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-bottom: 30px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-bottom: 60px;
        max-width: 66.6%;
      }
    }

    h3 {
      margin-bottom: 40px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-bottom: 30px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-bottom: 60px;
      }
    }
  }

  &.js-emu-inview {
    > .text {
      animation-name: slideIn;
    }

    .emu-image {
      animation-name: revealFromBottom;
    }
  }

  p {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-bottom: 30px;
      margin-left: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-bottom: 60px;
      font-size: 18px;
    }

    sup {
      font-weight: 500;
    }
  }

  li {
    color: var(--emu-semantic-colors-text-light);
  }

  &.text-isolated {
    .text {
      width: 100%;
      font-size: 16px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 85%;
        margin-left: auto;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        width: 70%;
      }

      p {
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          font-size: 18px;
        }

        sup {
          font-weight: 500;
        }
      }

      ol {
        padding-left: 0;
        font-size: 16px;
        list-style-position: inside;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          font-size: 18px;
        }
      }
    }
  }

  > .text {
    order: 4;
    width: 100%;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      width: 75%;
      margin-left: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      width: 50%;
      margin-left: auto;
    }

    p {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-bottom: 30px;
        margin-left: auto;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-top: 40px;
        margin-bottom: 60px;
        font-size: 18px;
      }

      sup {
        font-weight: 500;
      }
    }
  }

  &.text-highlighted {
    > .text {
      width: 100%;
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      color: var(--emu-semantic-colors-text-light);
      order: 1;
      opacity: 0;
      margin-left: unset;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 59%;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        width: 45%;
      }

      h4 {
        margin-bottom: 27px;
        margin-left: 0;
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-left: 15px;
        position: relative;
        max-width: 100%;
        background-color: var(--emu-semantic-colors-contrast-purple-600);
        font-family: var(--emu-semantic-font-families-body);
        font-size: 20px;
        font-weight: 700;
        font-style: italic;
        line-height: 28px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          font-size: 18px;
          line-height: 25px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-bottom: 60px;
          padding-top: 40px;
          padding-right: 40px;
          padding-bottom: 40px;
          padding-left: 0;
          font-size: 20px;
          line-height: 28px;
        }

        sup {
          font-weight: 500;
        }

        &::before {
          content: '';
          background-color: var(--emu-semantic-colors-contrast-purple-600);
          bottom: 0;
          display: block;
          left: 1px;
          position: absolute;
          top: 0;
          transform: translateX(-100%);
          width: 50vw;
          z-index: var(--emu-common-other-z-index-layer);
        }
      }

      + .text {
        margin-left: auto;
      }
    }

    > div:nth-child(2) {
      order: initial;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        order: 1;
      }
    }
  }

  //This is to place .image div after large breakpoint
  > div:nth-child(4) {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      order: 4;
    }
  }

  .image {
    display: flex;
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);
    margin-bottom: 30px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-bottom: 20px;
      padding-right: 0;
      padding-left: 0;
      width: 75%;
      margin-left: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 60px;
      max-width: var(--emu-semantic-sizing-content-max-width);
    }

    &::after {
      content: '';
      background-color: var(--emu-semantic-colors-transparent-magenta-100);
      bottom: 15px;
      position: absolute;
      top: 40px;
      right: -15px;
      bottom: -15px;
      width: 67%;
      z-index: var(--emu-common-other-z-index-behind);

      @include mq($emu-semantic-sizing-breakpoints-large) {
        bottom: -20px;
        top: 65px;
        width: 40%;
        left: 77%;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        bottom: -30px;
        top: 90px;
        width: 32vw;
      }
    }

    .emu-image {
      display: flex;
      align-items: center;
      aspect-ratio: 180/101;
      overflow: hidden;
      max-width: 820px;
      max-height: 470px;
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      opacity: 0;
      height: fit-content;
    }
  }

  > .title + .text + .image {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      order: 4;
    }
  }
}

.qa-section {
  > .container {
    overflow: hidden;
  }
}
// Country overrides
html[lang='he'],
html[lang='he_il'] {
  .juvederm-qacontainer {
    &.text-highlighted {
      > .text {
        h4 {
          font-family: var(--emu-semantic-font-families-body-assistant);
        }
      }
    }
  }
}
