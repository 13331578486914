.rtl {
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  i,
  small,
  blockquote,
  a,
  sup,
  input,
  .button {
    direction: rtl;
    text-align: right;
    letter-spacing: normal;
  }

  select {
    text-align: right;
  }

  input {
    padding-right: 10px;
  }

  select {
    text-align: right;
  }

  input {
    padding-right: 10px;
  }

  ul,
  ol {
    list-style-position: inside;
  }

  ul {
    list-style-type: none;
    padding-right: 0;

    li {
      padding-left: 0;

      &::before {
        right: -10px;
      }
    }
  }

  a span {
    text-align: left;
  }

  .cmp-teaser {
    h1,
    h2 {
      text-align: center;
    }
  }

  .juvederm-scrollreveal {
    .cmp-title__text {
      padding-right: 2px;
    }
  }

  .juvederm-treatmentareas {
    .cmp-title__text {
      margin-right: 15px;
    }
  }

  .juvederm-testimonial-image-carousel {
    .cmp-title__text {
      margin-right: 15px;
    }
  }

  .juvederm-faqshort {
    .aaaem-accordion {
      &__title {
        padding-left: 15px;
        padding-right: 0;
      }

      .cmp-text li {
        padding-left: 0;
        padding-right: 15px;

        &::before {
          right: 0;
        }
      }
    }

    .aaaem-accordion__panel {
      .js-badger-accordion-panel-inner {
        padding-left: 45px;
        padding-right: 0;
      }
    }

    h5 {
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 30px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        font-size: 27px;
        line-height: 35px;
        margin-bottom: 20px;
      }
    }
  }

  .juvederm-productsshowcase {
    .aaaem-carousel__actions {
      left: 0;
    }
  }

  .juvederm-topicmenu {
    &__container {
      flex-direction: row-reverse;
    }

    .emu-navigation__menu--level-0 {
      direction: rtl;
    }
  }

  .juvederm-keytakeaways__container {
    > .text {
      &::after {
        left: unset;
        right: 15px;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          right: 45px;
        }
      }
    }
  }

  .juvederm-qacontainer {
    h2 {
      margin-left: auto;
    }

    ol,
    ul {
      direction: rtl;
      padding-right: 0;
    }
  }

  .juvederm-findaclinic__container {
    .juvederm-findaclinic-hero {
      .findaclinic-hero-text {
        .emu-title__text {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
  }

  .juvederm-findaclinic-hero {
    .findaclinic-hero-text {
      .locationservices {
        margin-left: auto;
        max-width: 290px;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          max-width: 450px;
        }
      }
    }
  }

  .c-testimonial-video-carousel {
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-right: 15px;
    }

    .cmp-title__text {
      margin-right: 15px;
    }

    .cmp-text {
      margin-right: 15px;
      max-width: 950px;
    }

    .aaaem-carousel[data-total-slides='1'] {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        .cmp-text {
          margin-left: auto;
        }
      }
    }
  }

  .icl-feed-container {
    .item__link-clinic {
      margin-right: 10px;
    }

    .item__description {
      direction: rtl;
    }

    .item__heading {
      margin-left: auto;
    }
  }

  .icl-detail {
    &__body {
      .icl-detail__button {
        margin-left: auto;
      }
    }

    &__text {
      direction: rtl;
    }
  }

  .footer-info {
    ol,
    ul {
      direction: rtl;
      padding-right: 0;
    }
  }

  .juvederm-footer {
    > .container {
      .cmp-container {
        > .button {
          text-align: left;
        }
      }
    }

    .responsivegrid {
      .aaaem-container {
        .accordion {
          dl dd.aaaem-accordion__panel {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              left: 29%;
            }

            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              left: 34%;
            }

            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              left: 37%;
            }

            &:last-child {
              @include mq($emu-semantic-sizing-breakpoints-large) {
                left: 74%;
                min-width: 150px;
              }

              @include mq($emu-semantic-sizing-breakpoints-x-large) {
                left: 80%;
              }

              @include mq($emu-semantic-sizing-breakpoints-x-large) {
                left: 83%;
              }
            }
          }
        }
      }
    }
  }

  .footer-list-notes {
    li {
      direction: ltr;
    }
  }

  &.p-download-brochure {
    .juvederm-brandstatements {
      .title {
        margin-left: auto;
      }

      .button {
        margin-left: auto;
        margin-right: 15px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          left: calc(50% + 230px);
          margin-right: 0;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          left: calc(50% + 340px);
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          left: calc(50% + 415px);
        }
      }
    }
  }

  // AAGSB-138: Icon position in the rtl for icl detail button
  .icl-detail__button .icl-detail__icon:before {
    right: 0;
    background-position: center;
    top: 0px;
  }
}
