footer.experiencefragment {
  .juvederm-footer {
    &.under18-footer-container {
      > .navigationlist {
        margin-top: var(--emu-common-spacing-none);
        padding-top: var(--emu-common-spacing-none);
        border-top: var(--emu-common-border-width-none);
      }

      .text {
        padding: var(--emu-common-spacing-none);

        .under18-footer-text {
          border: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-white);
          padding: var(--emu-common-spacing-small);
        }
      }
    }
  }
}
