.clinics-page,
#clinic-details-page {
  .modal-content {
    background-color: var(--emu-common-colors-white);
    text-align: center;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    width: 100%;
  
    @include mq($emu-semantic-sizing-breakpoints-large) {
      max-width: 610px;
      position: relative;
      bottom: initial;
      margin-bottom: 15%;
  
    }
  
    h1 {
      font: var(--emu-semantic-typography-wide-headings-xl);
      letter-spacing: -2px;
    }
  
    .close {
      color: var(--emu-semantic-colors-primary-purple-100);
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
    }
  
    &__header,
    &__message {
      max-width: 350px;
      margin-right: auto;
      margin-left: auto;
    }
  
    &__header {
      margin-top: 40px;
    }
  
    &__message {
      color: var(--emu-component-ingredients-text-text-color-light);
      padding: 20px;
    }
  }

  .iclfeed__modal,
  .icldetail__modal {
    .modal-content {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        padding-left: 120px;
        padding-right: 120px;
      }

      > .container {
        margin-top: 30px;

        .cmp-container {
          background-color: var(--emu-semantic-colors-none);
        }

        .cmp-title__text {
          margin-bottom: 20px;
          text-align: center;
        }

        .cmp-text {
          margin-bottom: 20px;

          p {
            color: var(--emu-semantic-colors-contrast-grey-200);
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }

    .modal-cta-container {
      [data-ref="close-cta"] {
        order: 1;
      }
    }

    .modal__cta--confirm {
      padding-bottom: 14px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 14px;
    }

    .modal__cta--cancel {
      border: 1px solid var(--emu-semantic-colors-transparent-magenta-200);
      background-color: var(--emu-common-colors-white);
      color: var(--emu-semantic-colors-text-light);
      padding-bottom: 14px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 14px;
      transition: all 0.3s;

      &:hover {
        background-color: var(--emu-semantic-colors-transparent-magenta-200);
        border-color: var(--emu-semantic-colors-transparent-magenta-200);
      }
    }
  }
}

#juvederm-phase1.clinics-page {
  .iclfeed__modal,
  .icldetail__modal {
    .modal__cta--confirm {
      background-color: var(--emu-semantic-colors-primary-purple-100);
      color: var(--emu-common-colors-white);

      &:hover {
        border-color: var(--emu-semantic-colors-primary-purple-100);
      }
    }

    .modal__cta--cancel {
      padding-bottom: 14px;
      padding-top: 14px;

      &:hover {
        border-color: var(--emu-semantic-colors-transparent-magenta-200);
      }
    }
  }
}