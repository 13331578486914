body {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
  letter-spacing: -3px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
    letter-spacing: -4px;
  }
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
  letter-spacing: -2px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    font: var(--emu-component-ingredients-title-h2-typography-narrow);
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
    letter-spacing: -3px;
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);
  letter-spacing: -0.5px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    font: var(--emu-component-ingredients-title-h3-typography-narrow);
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
    letter-spacing: -1px;
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

p {
  font: var(--emu-component-ingredients-text-text-typography-narrow);
  color: var(--emu-component-ingredients-text-text-color-light);

  @include mq($emu-semantic-sizing-breakpoints-large) {
    font: var(--emu-component-ingredients-text-text-typography-narrow);
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font: var(--emu-component-ingredients-text-text-typography-wide);
  }
}

small {
  color: var(--emu-semantic-colors-text-light);
  display: block;
  font-size: 12px;
  line-height: 18px;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font-size: 14px;
    line-height: 20px;
  }
}

sup {
  line-height: 0;
  font-size: 50%; // as per comments from AAGSB-22 & AAGSB-14
  font-family: inherit;
}

// Country overrides
html[lang='he'],
html[lang='he_il'] {
  body {
    font-family: var(--emu-semantic-font-families-heading-assistant);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--emu-semantic-font-families-heading-assistant);
  }
  h1 {
    font: var(--emu-component-ingredients-title-h1-typography-narrow-assistant);

    @include mq($emu-semantic-sizing-breakpoints-large) {
      font: var(--emu-component-ingredients-title-h1-typography-wide-assistant);
    }
  }

  h2 {
    font: var(--emu-component-ingredients-title-h2-typography-narrow-assistant);

    @include mq($emu-semantic-sizing-breakpoints-large) {
      font: var(
        --emu-component-ingredients-title-h2-typography-narrow-assistant
      );
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      font: var(--emu-component-ingredients-title-h2-typography-wide-assistant);
    }
  }

  h3 {
    font: var(--emu-component-ingredients-title-h3-typography-narrow-assistant);

    @include mq($emu-semantic-sizing-breakpoints-large) {
      font: var(
        --emu-component-ingredients-title-h3-typography-narrow-assistant
      );
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font: var(--emu-component-ingredients-title-h3-typography-wide-assistant);
    }
  }

  h4 {
    font: var(--emu-component-ingredients-title-h4-typography-narrow-assistant);
  }

  h5 {
    font: var(--emu-component-ingredients-title-h5-typography-narrow-assistant);
  }

  p {
    font: var(
      --emu-component-ingredients-text-text-typography-narrow-assistant
    );

    @include mq($emu-semantic-sizing-breakpoints-large) {
      font: var(
        --emu-component-ingredients-text-text-typography-narrow-assistant
      );
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font: var(
        --emu-component-ingredients-text-text-typography-wide-assistant
      );
    }
  }
}
