.juvederm-disclaimer-page {
  margin-top: 120px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-top: 180px;
  }

  > .title,
  > .text {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
  }

  > .title {
    .emu-title__text {
      margin-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-bottom: 70px;
      }
    }
  }

  > .text {
    margin-bottom: 60px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 90px;
    }

    .aaaem-text {
      padding-left: 15px;
      padding-right: 15px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-left: auto;
        margin-right: auto;
        max-width: 83%;
      }

      h4 {
        font-size: 27px;
        letter-spacing: -0.5px;
        line-height: 35px;
      }

      p {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 20px;
        margin-top: 20px;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 18px;
        }
      }

      ul {
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
      }

      li {
        color: var(--emu-semantic-colors-text-light);
        font-size: 16px;
        line-height: 30px;
        padding-left: 10px;
        position: relative;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 18px;
        }

        &::before {
          content: "·";
          left: 0;
          line-height: inherit;
          position: absolute;
          vertical-align: middle;
        }
      }
    }
  } 
}