.juvederm-mediatext {
  display: grid;
  flex-direction: column;
  padding-top: 0;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 0;
  gap: 30px;
  margin-bottom: 30px;
  max-width: var(--emu-semantic-sizing-content-max-width);

  @include mq($emu-semantic-sizing-breakpoints-large) {
    grid-template-columns: 50%;
    margin-bottom: 60px;
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    margin-bottom: 130px;
    margin-left: auto;
    margin-right: auto;
  }

  > .title {
    grid-row: 1/2;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      max-width: 90%;
    }

    h3 {
      font-size: 27px;
      letter-spacing: -0.5px;
      line-height: 35px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-bottom: 0;
      }

      @include mq($emu-semantic-sizing-breakpoints-large) {
        font-size: 41px;
        letter-spacing: -1px;
        line-height: 45px;
      }
    }
  }

  > .image {
    grid-row: 3/4;
    opacity: 0;

    img {
      aspect-ratio: 5/6;
      object-fit: cover;
    }
  }

  > .text {
    grid-row: 4/5;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      max-width: 90%;
    }

    &.juvederm-mediatext__textwrapper {
      grid-row: 2/3;
      position: relative;
    }

    .cmp-text {
      margin-bottom: 30px;

      &.text-highlighted {
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 20px;
        background-color: var(--emu-semantic-colors-contrast-purple-600);

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          padding-bottom: 40px;
          padding-top: 40px;
          padding-right: 30px;
        }

        p,
        h4 {
          font-weight: 700;
          font-style: italic;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            font-size: 18px;
            line-height: 25px;
          }
        }

        h4 {
          font-family: var(--emu-semantic-font-families-body);
        }

        p {
          margin-top: 20px;
        }
      }
    }

    p,
    h4,
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        font-size: 18px;
      }
    }

    li {
      color: var(--emu-semantic-colors-text-light);
    }

    p:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  &.juvederm-mediatext--ltr {
    &.juvederm-mediatext--no-title {
      > .text {
        grid-row: 1;
        padding-top: 20px;
      }
    }

    > .title {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: 1/2;
        margin-top: 20px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-left: 10%;
        margin-top: 40px;
      }
    }

    > .image {
      margin-left: -15px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-row: 1/5;
        grid-column: 1/1;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-left: -70px;
      }

      img {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          aspect-ratio: 369/461;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          aspect-ratio: 25/26;
        }
      }
    }

    > .text {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-row: unset;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-left: 10%;
      }

      &.juvederm-mediatext__textwrapper {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          grid-row: unset;
        }

        .text::before {
          background-color: var(--emu-semantic-colors-contrast-purple-600);
          content: '';
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(100%);
          width: 100vw;
        }
      }

      .cmp-text {
        &.text-highlighted {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            margin-left: -30px;
            padding-left: 30px;
            padding-right: 0;
          }
        }
      }
    }
  }

  &.juvederm-mediatext--rtl {
    > .title {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: 1/1;
        margin-top: 15px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-top: 40px;
      }
    }

    > .image {
      margin-right: -15px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-row: 1/5;
        grid-column: 4/2;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-right: -70px;
      }

      img {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          aspect-ratio: 25/26;
        }
      }
    }

    > .text {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-row: unset;
      }

      &.juvederm-mediatext__textwrapper {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          grid-row: unset;
        }

        .text::before {
          background-color: var(--emu-semantic-colors-contrast-purple-600);
          content: '';
          height: 100%;
          position: absolute;
          top: 0;
          transform: translateX(-100%);
          width: 100vw;
        }
      }

      .cmp-text {
        &.text-highlighted {
          margin-left: -15px;
        }
      }
    }
  }

  &.js-emu-inview {
    .text,
    .image {
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
    }

    .text {
      .text-highlighted {
        animation-name: slideIn;
      }
    }

    .image {
      animation-name: revealFromBottom;
    }
  }

  .new {
    display: none;
  }
}

.qa-section {
  > .container {
    overflow: hidden;
  }
}
// Country overrides
html[lang='he'],
html[lang='he_il'] {
  .juvederm-mediatext {
    > .text {
      .cmp-text {
        &.text-highlighted {
          h4 {
            font-family: var(--emu-semantic-font-families-body-assistant);
          }
        }
      }
    }
  }
}
