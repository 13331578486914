.juvederm-treatmentareas {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  max-width: var(--emu-semantic-sizing-content-max-width);
  margin-bottom: 95px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;

  .emu-title {
    padding-top: 60px;
  }

  &:before {
    #home & {
      content: '';
      height: 345px;
      width: 115px;
      background-color: var(--emu-semantic-colors-transparent-magenta-100);
      position: absolute;
      @include mq($emu-semantic-sizing-content-max-width) {
        // position pink patch at leftmost of page
        margin-left: calc(($emu-semantic-sizing-content-max-width - 100vw) / 2);
      }
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        height: 540px;
        width: 245px;
      }
    }
  }

  .tabs {
    ul.aaaem-tabs__tablist {
      backdrop-filter: blur(10px);
      background-color: var(--emu-semantic-colors-transparent-white-500);
      border-radius: 1.4rem;
      display: flex;
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
      justify-content: center;
      width: fit-content;
      li {
        a {
          background: var(--emu-common-colors-transparent);
          border: none;
          color: var(--emu-semantic-colors-primary-purple-100);
          cursor: pointer;
          display: block;
          font-size: 14px;
          letter-spacing: 0.2px;
          line-height: 20px;
          overflow: hidden;
          padding-top: 2px;
          padding-bottom: 3px;
          padding-right: 12px;
          padding-left: 12px;
          position: relative;
          text-transform: uppercase;
          transition: 0.3s ease;
          z-index: var(--emu-common-other-z-index-layer);
          &:before {
            background-color: var(--emu-semantic-colors-primary-purple-100);
            border-radius: 12px;
            content: '';
            height: calc(100% - 6px);
            position: absolute;
            top: 3px;
            transition: 0.3s ease;
            width: calc(100% - 3px);
            will-change: transform;
            z-index: var(--emu-common-other-z-index-behind);
          }
        }
        &:first-child {
          margin-left: 0;
          border-radius: 14px 0 0 14px;
          margin-right: -1px;
          a {
            &:before {
              left: 3px;
              transform: translateX(calc(100% + 1px));
            }
            &[aria-selected='true'] {
              color: var(--emu-common-colors-white);
              &:before {
                transform: translateX(0);
              }
            }
          }
        }
        &:last-child {
          border-radius: 0 14px 14px 0;
          margin-left: -1px;
          a {
            &:before {
              left: 0;
              transform: translateX(calc(-100% - 1px));
            }
            &[aria-selected='true'] {
              color: var(--emu-common-colors-white);
              &:before {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }

    .aaaem-tabs__tabpanels {
      .aaaem-tabs__tabpanel {
        .container.responsivegrid {
          .aaaem-container.cmp-container {
            display: grid;
            position: relative;
            .image {
              padding-bottom: 20px;
              border-bottom: 1px solid
                var(--emu-semantic-colors-transparent-black-200);
              margin-bottom: 10px;
              @include mq($emu-semantic-sizing-breakpoints-large) {
                border-bottom: none;
                width: calc(50% - 15px);
              }

              .emu-image-map__map-marker-container-wrapper {
                .emu-image-map__map-marker-container {
                  .emu-mapping-marker {
                    backdrop-filter: blur(14.4px);
                    background-color: var(
                      --emu-semantic-colors-transparent-white-400
                    );
                    border: 1px solid
                      var(--emu-semantic-colors-transparent-white-600);
                    cursor: pointer;
                    height: 40px;
                    padding: 0;
                    width: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    @include mq($emu-semantic-sizing-breakpoints-medium) {
                      height: 56px;
                      width: 56px;
                    }

                    @include mq($emu-semantic-sizing-breakpoints-large) {
                      height: 40px;
                      width: 40px;
                    }

                    @include mq($emu-semantic-sizing-breakpoints-x-large) {
                      height: 56px;
                      width: 56px;
                    }

                    &:before {
                      content: '+';
                      z-index: 2;
                      position: absolute;
                      font-size: 14px;

                      @include mq($emu-semantic-sizing-breakpoints-x-large) {
                        font-size: 20px;
                      }
                    }
                    &:after {
                      content: none;
                    }
                    .circle-wrapper {
                      height: calc(100% - 10px);
                      width: calc(100% - 10px);
                      border-radius: 50%;
                      border: 1px solid
                        var(--emu-semantic-colors-transparent-purple-light);
                      span {
                        height: 100%;
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        width: 50%;
                        &:after {
                          content: none;
                        }
                        &:before {
                          width: 0;
                        }

                        &.rotateLeft {
                          left: 0;
                        }
                        &.rotateRight {
                          right: 0;
                        }
                      }
                      &:before {
                        content: none;
                      }
                      &:after {
                        content: none;
                      }
                    }
                    &.active {
                      .circle-wrapper {
                        span {
                          height: 100%;
                          overflow: visible;
                          position: absolute;
                          top: 0;
                          width: 50%;

                          &:before {
                            border-radius: 50%;
                            content: '';
                            display: block;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            transform: rotate(-135deg);
                            width: 200%;
                            will-change: transform;
                          }

                          &.rotateLeft {
                            &:before {
                              animation: rotateLeft 7s linear forwards;
                              border-bottom: 1.5px solid
                                var(--emu-semantic-colors-primary-purple-100);
                              border-left: 1.5px solid
                                var(--emu-semantic-colors-primary-purple-100);
                              left: 0;
                              background-color: var(
                                --emu-common-colors-transparent
                              );
                            }
                          }
                          &.rotateRight {
                            &:before {
                              animation: rotateRight 7s linear forwards;
                              border-right: 1.5px solid
                                var(--emu-semantic-colors-primary-purple-100);
                              border-top: 1.5px solid
                                var(--emu-semantic-colors-primary-purple-100);
                              right: 0;
                              background-color: var(
                                --emu-common-colors-transparent
                              );
                            }
                          }
                        }
                      }
                    }
                    &.overlay {
                      background-color: var(
                        --emu-semantic-colors-primary-purple-600
                      );
                      &:before,
                      &:after {
                        content: none;
                      }
                      span {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            .container.responsivegrid {
              visibility: hidden;
              grid-area: 2/1;
              &.visible {
                visibility: visible;
              }
              @include mq($emu-semantic-sizing-breakpoints-large) {
                position: absolute;
                grid-area: unset;
                border-top: 1px solid
                  var(--emu-semantic-colors-transparent-black-200);
                right: 0;
                top: 0;
                width: calc(50% - 15px);
              }
              .text {
                p {
                  margin-top: 10px;
                  margin-bottom: 20px;
                  font-size: 16px;
                  line-height: 30px;
                  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
                    font-size: 18px;
                  }
                }

                h3 {
                  margin-top: 15px;
                }
              }
              .button {
                margin-top: 40px;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes rotateLeft {
  0%,
  50% {
    transform: rotate(-135deg);
  }
  to {
    transform: rotate(45deg);
  }
}
@keyframes rotateRight {
  0% {
    transform: rotate(-135deg);
  }
  50%,
  to {
    transform: rotate(45deg);
  }
}
