.juvederm-home {
  > .text {
    margin-bottom: 60px;
    margin-top: 120px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-bottom: 80px;
      margin-top: 110px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 120px;
    }
  }
}