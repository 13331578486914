.juvederm-testimonial-image-carousel {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 90px;
  padding-left: var(--emu-semantic-spacing-horizontal);

  @include mq($emu-semantic-sizing-breakpoints-medium) {
    padding-bottom: var(--emu-component-containers-scroll-reveal-padding-bottom-narrow);
    padding-left: var(--emu-semantic-spacing-horizontal);
  }

  &::before {
    content: "";
    position: absolute;
    background-color: var(--emu-semantic-colors-transparent-grey-100);
    height: 300px;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      height: 350px;
    }
  }

  .title {
    position: relative;
    max-width: var(--emu-semantic-sizing-content-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  .carousel {
    position: relative;
    max-width: var(--emu-semantic-sizing-content-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  .aaaem-carousel {
    &__content {
      display: flex;
    }

    &__item {
      position: relative;

      &-container {
        &::before {
          background-color: var(--emu-semantic-colors-transparent-grey-200);
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          position: absolute;
          width: calc(100% - 30px);
        }

        &.carousel__slide--hidden {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .emu-title__text,
  .cmp-title__text {
    font-size: 27px;
    line-height: 35px;
    margin-bottom: 40px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      font-size: 54px;
      line-height: 50px;
      margin-bottom: 60px;
    }
  }

  .image {
    margin-bottom: 20px;
  }

  .carousel__item {
    &__name {
      margin-bottom: 30px;
    }
  
    &__testimonial {
      p {
        margin-bottom: 50px;
        font-family: var(--emu-semantic-font-families-heading);
        font-size: 18px;
        line-height: 30px;
  
        @include mq($emu-semantic-sizing-breakpoints-medium) {
          font-size: 20px;
          line-height: 30px;
        }

        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-bottom: 70px;
        }
      }
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .juvederm-testimonial-image-carousel {
    .carousel__item {    
      &__testimonial {
        p {
          font-family: var(--emu-semantic-font-families-heading-assistant);
        }
      }
    }
  }
}