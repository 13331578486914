.juvederm-footer {
  .responsivegrid {
    .aaaem-container__position-static {
      .accordion {
        margin-bottom: 0;
        background-color: transparent;
        @include mq($emu-semantic-sizing-breakpoints-large) {
          display: block;
        }
        &.aem-GridColumn.aem-GridColumn--default--12 {
          @include mq($emu-semantic-sizing-breakpoints-large) {
            width: 66.6667%;
            height: 100%;
          }
        }
        dl {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0;
          margin-right: 0;
          background-color: transparent;
          @include mq($emu-semantic-sizing-breakpoints-large) {
            display: flex;
            height: 100%;
            position: relative;
          }
          dt {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
            z-index: 2;
            position: relative;
            @include mq($emu-semantic-sizing-breakpoints-large) {
              width: 50%;
              height: fit-content;
            }
            p {
              border-top: 1px solid
                var(--emu-semantic-colors-transparent-white-100);
              border-bottom: 0;
              padding-top: 20px;
              padding-bottom: 20px;
              padding-left: 0;
              padding-right: 0;
              @include mq($emu-semantic-sizing-breakpoints-large) {
                border-top: 0;
              }
              span {
                color: var(--emu-common-colors-white);
                font-size: 16px;
                font-weight: 400;
                &.aaaem-accordion__title {
                  font: inherit;
                  font-size: 16px;
                  font-weight: 400;
                  @include mq($emu-semantic-sizing-breakpoints-large) {
                    font: inherit;
                    font-size: 16px;
                    font-weight: 400;
                  }
                }
                &.aaaem-accordion__icon {
                  &:before {
                    border-bottom: 1px solid var(--emu-common-colors-white);
                    border-left: 1px solid var(--emu-common-colors-white);
                    content: '';
                    height: 13px;
                    position: absolute;
                    right: 0;
                    top: -5px;
                    transition: 0.3s;
                    width: 13px;
                    will-change: transform;
                    background-color: transparent;
                    left: unset;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    transform: translateY(0) rotate(-45deg);
                  }
                  &:after {
                    content: none;
                  }
                  @include mq($emu-semantic-sizing-breakpoints-large) {
                    display: none;
                  }
                }
              }
              &[aria-expanded='true'] {
                span {
                  &.aaaem-accordion__icon {
                    &:before {
                      transform: translateY(0) rotate(135deg);
                    }
                  }
                }
              }
            }
          }
          dd {
            &.aaaem-accordion__panel {
              @include mq($emu-semantic-sizing-breakpoints-large) {
                display: inline-table;
                position: absolute;
                visibility: visible;
                top: 50px;
              }
              &:first-child {
                @include mq($emu-semantic-sizing-breakpoints-large) {
                  left: 0;
                }
              }
              &:last-child {
                @include mq($emu-semantic-sizing-breakpoints-large) {
                  left: 50%;
                }
              }
            }

            .js-badger-accordion-panel-inner {
              padding-top: 0;
              padding-bottom: 0;
              .responsivegrid {
                .cmp-container {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }
            .navigationlist {
              display: block;
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 0;
              padding-right: 0;
              &.text.aem-GridColumn.aem-GridColumn--default--12 {
                @include mq($emu-semantic-sizing-breakpoints-large) {
                  float: none;
                  width: 100%;
                }
              }
              .emu-navigation__content-wrapper {
                position: initial;
                .emu-navigation {
                  background-color: transparent;
                  ul {
                    @include mq($emu-semantic-sizing-breakpoints-large) {
                      display: block;
                    }
                    li {
                      a {
                        font-size: 16px;
                        font-weight: 400;
                        color: var(--emu-semantic-colors-transparent-white-600);
                        border: none;
                        &:hover {
                          background-color: transparent;
                          color: var(--emu-common-colors-white);
                        }
                        &:active {
                          background-color: transparent;
                          color: var(--emu-common-colors-white);
                        }
                      }
                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
