.juvederm-404-page {
    margin-top: 120px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    max-width:  var(--emu-semantic-sizing-content-max-width);
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-top: 180px;
      margin-bottom: 90px;
    }
    .title{
      margin-bottom: 60px;
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
       margin-bottom: 90px;
      }
      h2{
        font-size: 41px;
        line-height: 45px;
        letter-spacing: -2px;
        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 61px;
          line-height: 70px;
          letter-spacing: -3px;
        }
      }
    }
    .text{
      p{
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 40px;
        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 18px;
        }
      }
    }
}
