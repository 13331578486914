.clinic-popup { 
    &__heading {
        margin-bottom: 7px;
        font-size: 18px;
        line-height: 26px;
        color: var(--emu-semantic-colors-primary-purple-100);
        font-family: var(--emu-semantic-font-families-body);
        font-weight: 500;
    }

    &__description {
        margin-bottom: 20px;
        font-family: var(--emu-semantic-font-families-body);
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: var(--emu-semantic-colors-contrast-grey-200);
    }

    &__buttons-container {
        display: flex;
    }

    &__link {
        color: var(--emu-semantic-colors-primary-purple-100);
        cursor: pointer;
        display: inline-block;
        font-family: var(--emu-semantic-font-families-body);
        font-size: 18px;
        font-weight: 400;
        height: 50px;
        line-height: 30px;
        min-width: 50px;
        overflow: hidden;
        position: relative;
        text-decoration: none;
        text-overflow: ellipsis;
        transition: 0.3s;
        white-space: nowrap;      

    }
    &__link-website,
    &__link-phone {
        border: 1px solid var(--emu-semantic-colors-transparent-magenta-200);
        border-radius: 25px;
        padding-top: 8px;
        padding-right: 20px;
        padding-bottom: 12px;
        padding-left: 20px;
        margin-right: 10px;
        font-weight: 400;
    }

    &__link-website {
        color: var(--emu-common-colors-white);
        border: 1px solid var(--emu-semantic-colors-primary-purple-100);
        background-color: var(--emu-semantic-colors-primary-purple-100);

        &:hover,
        &:visited {
            color: var(--emu-common-colors-white);
        }
    }
    
    &__link-phone {
        color: var(--emu-semantic-colors-primary-purple-100);
        background-color: var(--emu-common-colors-white);

        &:hover {
            background-color: var(--emu-semantic-colors-transparent-magenta-200);
            border-color: var(--emu-semantic-colors-transparent-magenta-200);
        }
    }

    &__link-clinic {
        border-radius: 50%;
        padding: 8px;
        margin-left: auto;
        border: 1px solid var(--emu-semantic-colors-transparent-magenta-200);

        &:hover {
            background-color: var(--emu-semantic-colors-transparent-magenta-200);
            border-color: var(--emu-semantic-colors-transparent-magenta-200);
        }
    }

    &__link-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

//'importans' are required to overwite google styles
.gm-style .gm-style-iw-c {
    box-shadow: 0 1px 4px 0 var(--emu-semantic-colors-transparent-black-300);
    padding-left: 16px;
    border-radius: 0;
    max-width: 340px!important;

    > button {
        top: 2px!important;
        right: 7px!important;
    }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
    .clinic-popup { 
        &__heading {
            font-family: var(--emu-semantic-font-families-body-assistant);
        }
    
        &__description {
            font-family: var(--emu-semantic-font-families-body-assistant);
        }    
        &__link {
            font-family: var(--emu-semantic-font-families-body-assistant);    
        }
    }
}