.juvederm-footnotes{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    max-width: var(--emu-semantic-sizing-content-max-width);

    @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-bottom: 60px;
      }

    p {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 15px;
        font-size: 12px;
    }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
    .juvederm-footnotes{
        p {
            margin-top: 20px;
            margin-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;
            line-height: 15px;
            font-size: 12px;
        }
    }

}