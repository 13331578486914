.juvederm-ba-carousel {
  background-color: var(--emu-semantic-colors-primary-purple-100);

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-top: 30px;
    padding-top: 30px;
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-top: 60px;
  }

  &.js-emu-inview {
    > .container {
      animation-name: slideIn;
    }
  }

  > .container {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
    opacity: 0;

    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
      max-width: var(--emu-semantic-sizing-content-max-width);
    }
  }

  &__text {
    background-color: var(--emu-semantic-colors-primary-purple-100);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 60px;
    width: 100%;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      position: absolute;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-top: 90px;
    }

    h2 {
      color: var(--emu-common-colors-white);
      margin-bottom: 60px;
      padding-right: 30px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        max-width: 50%;
      }
    }
  }

  .aaaem-carousel {
    display: flex;
    flex-direction: column;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      flex-direction: row;
      padding-right: 15px;
    }

    &__indicators {
      counter-reset: indicator-counter;
      margin-bottom: 50px;
      margin-top: 30px;
      padding-left: 15px;
      padding-right: 15px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 30px;
      }
    }

    &__indicator {
      border: 0;
      border-radius: 0;
      display: flex;
      font-size: 0;
      height: 2px;
      position: relative;
      width: 25%;

      &::after {
        color: var(--emu-semantic-colors-transparent-white-600);
        counter-increment: indicator-counter;
        content: "0"counter(indicator-counter);
        display: block;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 5px;
        position: absolute;
        text-align: left;
        text-indent: 0;
        top: -25px;
        width: 100%;
      }

      &.tns-nav-active {
        &::after {
          color: var(--emu-common-colors-white);
        }
      }

      &.show-progress {
        overflow: visible;

        span {
          height: 2px;
        }
      }
    }

    &__item {
      font-size: 0;
    }

    .tns-outer {
      order: 2;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        display: flex;
        margin-top: -60px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-top: -90px;
      }
    }
  }
}