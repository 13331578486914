@import 'styles/includes/i-fonts';
@import 'styles/includes/i-typography';
@import 'styles/css-variables';
@import 'styles/global/g-animations';

@import 'styles/global/g-base';
@import 'styles/global/g-utilities';
@import 'styles/global/g-pages';
@import 'styles/global/g-animations';
@import 'styles/global/g-rtl';
