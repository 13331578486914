.emu-comparison-slider {
  &__slider {
    &__slot {
      .text {
        position: absolute;
        top: 10px;

        .cmp-text {
          background-color: var(--emu-semantic-colors-contrast-white-100);
          border-radius: 15px;
          padding-bottom: 2px;
          padding-left: 25px;
          padding-right: 25px;
          padding-top: 2px;

          p {
            color: var(--emu-semantic-colors-text-light);
            font-size: 14px;
            line-height: 25px;
          }
        }
      }

      &--first {
        .text {
          left: 10px;
        }
      }

      &--second {
        .text {
          right: 10px;
        }
      }
    }
  }

  img-comparison-slider {
    --handle-position-start: 92%;
  }
}