.juvederm-500-page {
  margin-top: 120px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-top: 180px;
  }

  > .text,
  > .button {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
  }

  > .text {
    .aaaem-text {
      margin-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-left: auto;
        margin-right: auto;
        max-width: 66%;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-bottom: 70px;
      }

      h1 {
        font-size: 41px;
        letter-spacing: -2px;
        line-height: 45px;
        margin-bottom: 20px;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 54px;
          line-height: 50px;
        }
      }
    }
  }

  > .button {
    margin-bottom: 80px;
    margin-top: 20px;
    text-align: center;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-bottom: 90px;
    }
  }
}